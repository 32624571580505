<template>
  <div id="13_automatic_investment_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Consentimientos</p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClassFull">
                            <vx-card class="border-primary">
                              <div style="height:400px; overflow-y:scroll; overflow-x: hidden;">
									<!-- VERSION 2.0 DE CONSENTIMIENTO DE INVERSION AUTOMATICA -->
									<automatic-investment-consent-v2 :fill-user-data="true"/>
								</div>
								<p class="mt-base">
									Podrás habilitar y deshabilitar la Inversión Automática (que se encuentra desactivada por defecto) en el momento en que lo requieras en el apartado “Inversión Automática”.
								</p>
								<p class="mt-5">
									Consiento que la Inversión Automática utilice los recursos de:
								</p>
								<div class="checkbox-display">
									<vs-checkbox
									name="terms"
									v-model.lazy="acceptAutoInvestApplicantPayments">
									</vs-checkbox>
									Los pagos de Solicitantes y Operaciones no perfeccionadas.
								</div>
								<div class="checkbox-display">
									<vs-checkbox
									name="terms"
									v-model.lazy="acceptAutoInvestOtherResources">
									</vs-checkbox>
									Otros recursos.
								</div>
								<p class="mt-5 text-center">
									Al dar clic en Autorizar aceptas los <a class="inline-link-primary" target="_blank" rel="noopener" @click="popUpAutomaticInvestmentTermsContract=true" > términos y condiciones</a> de la Inversión Automática
								</p>
                            </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/3 sm:w-full w-full"></div>
                        <div class="vx-col md:w-2/3 sm:w-full w-full">
                          <vs-button id="13_automatic_investment_confirm_btn" :disabled="!acceptAutoInvestApplicantPayments || !acceptAutoInvestOtherResources" class="w-full mt-5" @click="saveDataForm"
                            >Autorizar y continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
      <!-- Popup para ver terminos y condiciones de inversion automatica -->
      <vs-popup :active.sync="popUpAutomaticInvestmentTermsContract" title="Términos y condiciones"
        class="extended-popup header-primary">
        <latest-automatic-investment-terms-conditions-contract />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import LatestAutomaticInvestmentTermsConditionsContract from "@views/investor/contracts/LatestAutomaticInvestmentTermsConditionsContract.vue";
import AutomaticInvestmentConsentV2 from "@views/investor/contracts/AutomaticInvestmentConsentV2.vue";

export default {
  mixins: [inputHelper],
  components: {LatestAutomaticInvestmentTermsConditionsContract, AutomaticInvestmentConsentV2},
  props: ["onboardingStepData"],
  data() {
    return {
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full",
      currentLocation:null,
      autoInvestmentContractType: 18,
      acceptAutoInvestApplicantPayments:false,
      acceptAutoInvestOtherResources:false,
      popUpAutomaticInvestmentTermsContract:false,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      },
    };
  },
  async mounted(){
    this.user.complete_name = this.onboardingStepData.full_name;
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    userName: function () {
      return (this.user === null) ? "-" : this.user.complete_name;
    },
  },
  methods: {
    async saveDataForm() {
      this.showLoading(true, "Guardando información...");
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          contractType: this.autoInvestmentContractType, 
          geolocation: geolocation
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
  },
};
</script>
<style scoped>
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}
.checkbox-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
</style>
