<template>
  <div id="pm_mex_3_legal_rep_name_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <h2 class="mt-base mb-base">Datos del representante legal:</h2>
                        <p class="text-2xl card-sub-title">Nombre</p>
                        <p>
                          Deberás ingresarlo tal cual aparece en tu identificación
                          oficial (INE, pasaporte, residencia permanente o
                          temporal)
                        </p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (firstName = firstName.toUpperCase())"
                            class="w-full"
                            label="Primer nombre *"
                            name="firstName"
                            v-validate="requiredRules"
                            v-model.lazy="firstName"
                            :danger="hasError('firstName')"
                            :danger-text="errorText('firstName')"
                            :success="isSuccess('firstName')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (secondName = secondName.toUpperCase())"
                            class="w-full"
                            label="Segundo nombre"
                            name="secondName"
                            v-model.lazy="secondName"
                            :danger="hasError('secondName')"
                            :danger-text="errorText('secondName')"
                            :success="isSuccess('secondName')"
                            icon-pack="feather"
                            :placeholder="optionalPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (lastName1 = lastName1.toUpperCase())"
                            class="w-full"
                            label="Primer apellido *"
                            name="lastName1"
                            v-validate="requiredRules"
                            v-model.lazy="lastName1"
                            :danger="hasError('lastName1')"
                            :danger-text="errorText('lastName1')"
                            :success="isSuccess('lastName1')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (lastName2 = lastName2.toUpperCase())"
                            class="w-full"
                            label="Segundo apellido"
                            name="lastName2"
                            v-model.lazy="lastName2"
                            :danger="hasError('lastName2')"
                            :danger-text="errorText('lastName2')"
                            :success="isSuccess('lastName2')"
                            icon-pack="feather"
                            :placeholder="optionalPlaceholder"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
                <div class="vx-col w-full p-0" style="min-height: 150px">
                  <!-- MAIN-DIV -->
                  <div class="px-6">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Género</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass" v-for="gender in genderOptions" :key="gender.value">
                            <rg-radio class="w-full" groupName="gender" :label="gender.label" :value="gender.value" v-model="genderSelected" />
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>



                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Datos generales
                        </p>
                        <!-- <p>
                          Solo para personas con
                          <strong>nacionalidad mexicana</strong>.
                        </p> -->
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass">
                          <label class="vs-input--label"
                            >Estado de nacimiento *</label
                          >
                          <v-select
                            class="vs-custom"
                            name="birthState"
                            :class="{
                              'has-error': hasError('birthState'),
                              'is-success': isSuccess('birthState'),
                            }"
                            v-model.lazy="birthState"
                            v-validate="requiredRules"
                            :options="collections.states"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            placeholder="Selecciona una opción"
                            :searchable="false"
                          >
                          </v-select>
                          <v-error v-if="hasError('birthState')" :error="errorText('birthState')" />
                        </div>
                        <div :class="colClass">
                          <label class="vs-input--label"
                            >Fecha de nacimiento *</label
                          >
                          <vs-input
                            class="w-full"
                            type="date" 
                            name="birthDate"
                            v-model.lazy="birthDate"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy"
                            :max="disabledDates.from" />
                          <!-- <datepicker
                            class="w-full"
                            v-model.lazy="birthDate"
                            name="birthDate"
                            :language="lang_es"
                            v-validate="requiredRules"
                            :danger="hasError('birthDate')"
                            :danger-text="errorText('birthDate')"
                            :success="isSuccess('birthDate')"
                            format="dd/MM/yyyy"
                            initial-view="year"
                            :disabled-dates="disabledDates"
                            :placeholder="requiredPlaceholder"
                            :use-utc="true"
                          /> -->
                        </div>
                      </div>
                      <div v-if="birthState == birthStateIdForeign" class="vx-row">
                        <div :class="colClassFull">
                          <label class="vs-input--label"
                            >País de nacimiento *</label
                          >
                          <v-select
                            class="vs-custom"
                            name="originCountry"
                            :class="{
                              'has-error': hasError('originCountry'),
                              'is-success': isSuccess('originCountry'),
                            }"
                            v-model.lazy="originCountry"
                            v-validate="requiredRules"
                            :options="collections.countries"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            placeholder="Selecciona una opción"
                            :searchable="false"
                          >
                          </v-select>
                          <v-error v-if="hasError('originCountry')" :error="errorText('originCountry')" />
                        </div>
                        <div :class="colClassFull">
                          <label class="vs-input--label"
                            >Nacionalidad *</label
                          >
                          <v-select
                            class="vs-custom"
                            name="citizenship"
                            :class="{
                              'has-error': hasError('citizenship'),
                              'is-success': isSuccess('citizenship'),
                            }"
                            v-model.lazy="citizenship"
                            v-validate="requiredRules"
                            :options="collections.countries"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            placeholder="Selecciona una opción"
                            :searchable="false"
                          >
                          </v-select>
                          <v-error v-if="hasError('citizenship')" :error="errorText('citizenship')" />
                        </div>
                      </div>
                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="pm_mex_3_legal_rep_name_confirm_btn" class="w-full mt-4" @click="saveDataForm"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>




              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  mixins: [inputHelper, dateHelper],
  props: ["onboardingStepData"],
  components: {
    Datepicker
  },
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: "(Opcional)",
      firstName: null,
      secondName: null,
      lastName1: null,
      lastName2: null,

      genderOptions: [
        {"value": 1, "label": "HOMBRE"},
        {"value": 2, "label": "MUJER"}
      ],
      genderSelected: null,


      mexicoId: 700,
      lang_es: es,
      collections: {
        states: [],
        countries: [],
      },
      disabledDates: {
        from: new Date(),
      },
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col lg:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      birthState: null,
      birthDate: null,
      originCountry: null,
      citizenship: null,
      birthStateIdForeign:null

    };
  },
  async beforeMount(){
    this.firstName = this.personalProfile.first_name;
    this.secondName = this.personalProfile.second_name;
    this.lastName1 = this.personalProfile.last_name_1;
    this.lastName2 = this.personalProfile.last_name_2;
    this.genderSelected = this.personalProfile.gender;
    await this.getCollections();
    this.birthState = this.personalProfile.birth_state;
    this.birthDate = this.personalProfile.birth_date;
    this.originCountry = this.personalProfile.origin_country;
    this.citizenship = this.personalProfile.citizenship;
    this.setDefaultDate();

  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    personalProfile() {
      return this.onboardingStepData.business_personal;
    },
  },
  methods: {
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'statesList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        colls.statesList.forEach(opt => {
          if (opt.name == "EXTRANJERO (FOREIGN)") {
            this.birthStateIdForeign = parseInt(opt.id);
          }
          this.collections.states.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        });
        colls.countriesList.forEach(opt => {
          this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    setDefaultDate(){
      let minAge = this.formatDatepicker(new Date(this.minDateForValidAge(18)));
      let validDate = this.birthDate != null ? new Date(this.birthDate).toISOString().slice(0, 10) : minAge;
      this.disabledDates.from = minAge;
      this.birthDate = validDate;
    },
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        if (this.birthState != this.birthStateIdForeign) {
          this.originCountry = this.mexicoId;
          this.citizenship = this.mexicoId;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          first_name: this.firstName,
          second_name: this.secondName,
          last_name_1: this.lastName1,
          last_name_2: this.lastName2,
          gender: this.genderSelected,
          birth_state: this.birthState,
          birth_date: this.birthDate,
          origin_country: this.originCountry,
          citizenship: this.citizenship
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      if (this.genderSelected == null) {
        this.errorMssg = "Debes seleccionar un género";
        setTimeout(() => this.errorMssg = null, 5000);
        return false;
      }
      // Valida la fecha de nacimiento que no sea menor a 18 años
      if(this.isAfter(this.birthDate, this.disabledDates.from)) {
          this.missingFieldsNotif(null, "La fecha de nacimiento no puede ser mayor a " + this.disabledDates.from);
          return;
      }
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
