<template>
  <div id="pm_mex_5_name_address_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Cuéntanos de tu negocio</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div v-if="isMounted && !failed" class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (businessName = businessName.toUpperCase())"
                            class="w-full"
                            label="Nombre"
                            name="businessName"
                            v-validate="requiredRules"
                            v-model.lazy="businessName"
                            :danger="hasError('businessName')"
                            :danger-text="errorText('businessName')"
                            :success="isSuccess('businessName')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <label class="vs-input--label bold"
                            >Giro</label
                          >
                          <v-select
                            class="vs-custom"
                            name="businessActivityId"
                            :class="{
                              'has-error': hasError('businessActivityId'),
                              'is-success': isSuccess('businessActivityId'),
                            }"
                            v-model.lazy="businessActivityId"
                            v-validate="requiredRules"
                            :options="collections.businessActivities"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            placeholder="Selecciona una opción"
                            :searchable="true"
                          >
                          </v-select>
                          <v-error v-if="hasError('businessActivityId')" :error="errorText('businessActivityId')" />
                        </div>
                      </div>
                      <div v-for="section in allSections" :key="section.id" :title="section.public_name" class="mb-5">
                        <p class="mb-4" v-if="section.public_description != null">{{section.public_description}}</p>
                        <div class="vx-row">
                          <template v-for="f in section.fields">
                            <!-- Campos automaticos -->
                            <form-field 
                              ref="allSections"
                              v-if="isAutoFormField(f)"
                              :key="f.id"
                              :f="f"
                              :dataContainer="businessAddress"
                              :collectionsRoot="collections"
                              :countriesList="collections.countries"
                              :onChangeReceptor="onFieldChange"
                              :evaluator="evaluateCondition" />
                          </template>
                        </div>
                      </div>

                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <load-error v-if="failed" />
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="pm_mex_5_name_address_confirm_btn" class="w-full mt-4" @click="saveInvestorData"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formHelper from "@mixins/formHelper";
import FormField from '@components/forms/FormField.vue';

export default {
  mixins: [inputHelper, formHelper],
  props: ["onboardingStepData"],
  components: {
    FormField
  },
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: "(Opcional)",
      businessName: null,
      businessActivityId: null,
      collections: {
        businessActivities: [],
        countries: [],
        states: [],
        states_investor_business_address: [],
        cities_investor_business_address: [],
        neighborhoods_investor_business_address: []
      },
      businessAddress: {
        city_id: null,
        zipcode: null
      },
      zip_code_investor_business_address:'',
      allSections: [],
      failed: false,
      isMounted: false,
      // auxiliares para evaluacion de condiciones
      mexicoCountryId: 700,
    };
  },
  beforeMount(){
    this.businessName = this.onboardingStepData.business_name;
    this.businessActivityId = this.onboardingStepData.business_activity_id;
    this.businessAddress = this.onboardingStepData.business_address;
    this.businessAddress.country_calling_code = this.onboardingStepData.business_phone.country_calling_code;
    this.businessAddress.phone = this.onboardingStepData.business_phone.phone;
    this.zip_code_investor_business_address = this.businessAddress.zipcode;
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getCollections();
    await this.getFormInfo();
    this.showLoading(false);
    this.isMounted = true;
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    should_fill_business_mexican_address(){
      return this.businessAddress.country_id == this.mexicoCountryId;
    }
  },
  methods: {
    async getCollections(){
      try {
        let collectionsObjects = ['businessActivities', 'countriesList', 'statesList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        this.collections.countries = colls.countriesList;
        this.collections.states = colls.statesList;
        colls.businessActivities.forEach(opt => {
          this.collections.businessActivities.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    async getFormInfo(){
      try{
        await this.getSectionInfo(105);
        this.allSections.forEach(s => {
          s.fields.forEach(f => {
            this.formFieldSetter(f, this, true);
          });
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getSectionInfo(id){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + id);
        this.allSections.push(response.data.section);
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
      if(condition != null && condition in this){
        return this[condition];
      }
    },
    async saveDataForm() {
      let ok = false;
      this.errorMssg = null;
      this.showLoading(true, "Guardando información...");
      try {
        if (this.businessAddress.municipality == null || this.businessAddress.municipality == '') {
          this.businessAddress.municipality = this.collections.cities_investor_business_address.find(f => f.id === this.businessAddress.city_id).name;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          business_name: this.businessName,
          business_activity_id: this.businessActivityId,
          street: this.businessAddress.street,
          ext_number: this.businessAddress.ext_number,
          int_number: this.businessAddress.int_number,
          zipcode: this.businessAddress.zipcode,
          state_id: this.businessAddress.state_id,
          city_id: this.businessAddress.city_id,
          neighborhood_id: this.businessAddress.neighborhood_id,
          municipality: this.businessAddress.municipality,
          country_calling_code: this.businessAddress.country_calling_code,
          phone: this.businessAddress.phone
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        ok = true;
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
      return ok;
    },
    async saveInvestorData() {
      let result = await this.runValidations();
      if(!result){
        this.missingFieldsNotif();
      }
      else{
        let res = await this.saveDataForm();
        if(res == true){
          this.saveSuccessNotif();
          this.$emit("updated", 1);
        }
        else {
          this.failedOperationNotif(null, this.errorMssg);
        }
      }
    },
    async runValidations() {
        // validar inputs instanciados en la vista
        let res2 = await this.$validator.validateAll();
        if(!res2){
            return false;
        }
        // validar radio
        let res1 = await this.validateFormFields();
        if(!res1){
            return false;
        }
        return true;
    },
    async validateFormFields(){
      let allValid = true;
      this.validatedFields = [];
      let grefs = this.$refs["allSections"];
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid == false){
          allValid = false;
        }
        else if(r.dirty == true) {
          let sp = f.specialValidation();
          if(sp != null){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
              continue;
            }
          }
          this.validatedFields.push(r);
        }
      }
      return allValid;
    },
    async reload_business_cities(){
      try {
        let sid = this.businessAddress.state_id;
        let res = await axios.get(`/api/register/cities/${sid}`);
        this.collections.cities = res.data;
        if(this.isMounted) this.businessAddress.city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    verify_zip_code_investor_business_address() {
      this.verifyZipCode(this.businessAddress, {
        zipCodeVariable: 'zip_code_investor_business_address',
        statesArray: 'states_investor_business_address',
        citiesArray: 'cities_investor_business_address',
        neighborhoodsArray: 'neighborhoods_investor_business_address'
      });
    },
    set_zip_code_values_investor_business_address() {
      this.setZipCodeAddressValues(this.businessAddress.id, this.businessAddress, {
        zipCodeVariable: 'zip_code_investor_business_address',
        statesArray: 'states_investor_business_address',
        citiesArray: 'cities_investor_business_address',
        neighborhoodsArray: 'neighborhoods_investor_business_address'
      });
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
