<template>
    <div>
          <div class="vx-row no-gutter items-center justify-center">
            <investor-onboarding-phone v-if="currentOnboardingStep == 'pm_mex_1_signup'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-legal-rep-data v-if="currentOnboardingStep == 'pm_mex_2_cellphone_checked'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-legal-rep-check-data v-if="currentOnboardingStep == 'pm_mex_3_legal_rep_name'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-name-address v-if="currentOnboardingStep == 'pm_mex_4_legal_rep_data_check'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-tax-profile v-if="currentOnboardingStep == 'pm_mex_5_name_address'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-bp-stakeholders v-if="currentOnboardingStep == 'pm_mex_6_fiscal_profile'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-kyc-profile v-if="currentOnboardingStep == 'pm_mex_7_partner_structure'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-documents-id-verification v-if="currentOnboardingStep == 'pm_mex_8_kyc_profile'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-risk-acceptance v-if="currentOnboardingStep == 'pm_mex_9_documents_id_verification'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-merchant-contract v-if="currentOnboardingStep == 'pm_mex_10_risk_acceptance'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-receipt-payments v-if="currentOnboardingStep == 'pm_mex_11_merchant_contract'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-automatic-investment v-if="currentOnboardingStep == 'pm_mex_12_receipt_payments'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
          </div>
    </div>
  </template>
  
  <script>
  import inputHelper from "@mixins/inputHelper";
  import InvestorOnboardingPhone from "@views/investor/onboarding/InvestorOnboardingPhone.vue";
  import InvestorOnboardingLegalRepData from "@views/investor/onboarding/pm/InvestorOnboardingLegalRepData.vue";
  import InvestorOnboardingLegalRepCheckData from "@views/investor/onboarding/pm/InvestorOnboardingLegalRepCheckData.vue";
  import InvestorOnboardingNameAddress from "@views/investor/onboarding/pm/InvestorOnboardingNameAddress.vue";
  import InvestorOnboardingTaxProfile from "@views/investor/onboarding/pm/InvestorOnboardingTaxProfile.vue";
  import InvestorOnboardingBpStakeholders from "@views/investor/onboarding/pm/InvestorOnboardingBpStakeholders.vue";
  import InvestorOnboardingKycProfile from "@views/investor/onboarding/pm/InvestorOnboardingKycProfile.vue";
  import InvestorOnboardingDocumentsIdVerification from "@views/investor/onboarding/pm/InvestorOnboardingDocumentsIdVerification.vue";
  import InvestorOnboardingRiskAcceptance from "@views/investor/onboarding/pm/InvestorOnboardingRiskAcceptance.vue";
  import InvestorOnboardingMerchantContract from "@views/investor/onboarding/pm/InvestorOnboardingMerchantContract.vue";
  import InvestorOnboardingReceiptPayments from "@views/investor/onboarding/pm/InvestorOnboardingReceiptPayments.vue";
  import InvestorOnboardingAutomaticInvestment from "@views/investor/onboarding/pm/InvestorOnboardingAutomaticInvestment.vue";

  export default {
    name: "InvestorMainOnboardingStepPagePm",
    mixins: [inputHelper],
    props: ["onboardingStepData"],
    components: {
      InvestorOnboardingPhone,
      InvestorOnboardingLegalRepData,
      InvestorOnboardingLegalRepCheckData,
      InvestorOnboardingNameAddress,
      InvestorOnboardingTaxProfile,
      InvestorOnboardingBpStakeholders,
      InvestorOnboardingKycProfile,
      InvestorOnboardingDocumentsIdVerification,
      InvestorOnboardingRiskAcceptance,
      InvestorOnboardingMerchantContract,
      InvestorOnboardingReceiptPayments,
      InvestorOnboardingAutomaticInvestment,
    },
    data(){
      return {
        colClassFull: "vx-col w-full mb-5"
      }
    },
    computed: {
      currentOnboardingStep(){
        return this.onboardingStepData.onboarding_step;
      }
    },
    methods: {
      getInvestorOnboardingData(){
        this.$emit("updated", 1);
      }
    }
  }
  </script>