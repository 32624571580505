<template>
    <div class="mb-4">
        <p class="bold mb-3 text-alert">
            ¿No lo recibiste? Puedes intentarlo de nuevo cuando el código expire.
        </p>
        <div>
            <div :class="isDisabled ? '' : 'clickable-img'" class="pl-20 pr-20" @click.stop="doWhatsappOption()">
                <vs-chip :color="isDisabled? '#c3c3c3' : ''" transparent class="mr-2 mb-2 bold w-full">Enviar Whatsapp a {{ phoneText }}</vs-chip>
            </div>
        </div>
        <div>
            <div :class="isDisabled ? '' : 'clickable-img'" class="pl-20 pr-20" @click.stop="doSMSOption()">
                <vs-chip :color="isDisabled? '#c3c3c3' : ''" transparent class="mr-2 mb-4 bold w-full">Enviar SMS a {{ phoneText }}</vs-chip>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "verification-code-options",
    props: {
        isDisabled: { type: Boolean, default: () => false },
        phoneText: { type: String, default: () => "NA" }
    },
    components: {},
    async beforeMount() {

    },
    data() {
        return {
        }
    },
    methods: {
        doWhatsappOption(){
            if (!this.isDisabled) {
                this.$emit("on-whatsapp-clicked");
            }
        },
        doSMSOption(){
            if (!this.isDisabled) {
                this.$emit("on-sms-clicked");
            }
        }
    }
}
</script>
  
<style lang="css" scoped>

.text-alert {
    line-height: 15px;
}


</style>
  