<template>
	<div id="pm_mex_7_partner_structure_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
		<div class="vx-row no-gutter items-center justify-center p-6">
			<vx-card class="login-card py-3 px-3">
				<div slot="no-body" class="">
					<div class="vx-col sm:w-full">
						<div class="login-tabs-container">
							<div class="vx-row m-0">
								<div class="vx-col w-full p-0" style="min-height: 200px">
									<!-- MAIN-DIV -->
									<div class="px-6 pt-5 pb-5">
										<!-- HEADER -->
										<div class="vx-row mb-4">
											<div class="vx-col w-full">
												<p class="text-2xl card-sub-title">Cuéntanos sobre tus socios</p>
											</div>
										</div>
										<vs-divider color="dark"></vs-divider>
										<!-- CONTENT -->
										<div class="main-form mt-base">
											<!-- ¿CUANTOS ACCIONISTAS TIENE LA EMPRESA? -->
											<div v-if="!hasSelected" class="vx-col sm:w-full md:w-3/5">
												<h5 class="bold black mb-2 mt-4">¿Cuántos accionistas tiene la empresa?</h5>
												<p class="text-md">Selecciona la cantidad de accionistas incluyendo al representante
													legal</p>
												<div class="vx-row mb-base">
													<div class="vx-col sm:w-1/2 lg:w-1/6 mb-8 mt-2"
														v-for="(item, index) in stakeholders_options" :key="index">
														<rg-radio class="w-full" groupName="needsInitialPayment" :label="item.label"
															:value="item.value" v-model="stakeholdersValue" />
													</div>

												</div>
												<vs-button color="black" @click.stop="selectStakeholders()">Continuar</vs-button>
											</div>
											<!-- CUANDO YA SE DEFINIO CUANTOS ACCIONISTAS SON -->
											<div v-else class="vx-col w-full">
												<h5 class="bold black mb-2">Completa la información del cuadro accionario</h5>
												<p class="text-md mb-base">Asegúrate de tener la información completa de los
													accionistas y
													representante legal.</p>

												<div class="vx-row">
													<div class="vx-col w-full mb-4">
														<vs-collapse id="stakeholder-form" v-if="hasSelected">
															<p v-if="stakeholdersValue >= 4" class="mb-4 bold">
																Si tienes más de 4 accionistas, completa la información del accionista
																con mayor
																acciones y/o pertenezca al consejo de administración.
															</p>
															<vs-collapse-item v-for="(item, index) in stakeholders"
																:class="!item.isValid ? 'danger' : ''">
																<div slot="header" class="bold">
																	{{ item.role_name }}
																</div>
																<stakeholder-form ref="stakeholderForm" :stakeholder="item"
																	:repLegalIsMainUser="rep_legal_is_main_user"
																	:hasMoreThanFourStakeholders="hasMoreThanFourStakeholders"
																	@change="onChangeRepLegal"></stakeholder-form>
															</vs-collapse-item>
														</vs-collapse>
													</div>
													<div class="vx-col w-full mb-base">
														<p class="bold mb-4">Porcentaje de acciones: {{ currentSharedValue }}%</p>
														<div class="flex mb-4">
															<vs-checkbox v-model="check_legal" name="legal" v-validate="requiredRules"
																:danger="hasError('legal')" :danger-text="errorText('legal')"
																:success="isSuccess('legal')">

															</vs-checkbox>
															<p>
																Confirmo que los datos registrados son verdaderos
																y coinciden con la información actual de la
																empresa (Asamblea, Acta Constitutiva, etc.)
															</p>
														</div>
													</div>
													<div class="vx-col w-full">
														<div class="flex">
															<vs-button color="black" type="border" class="mr-4"
																@click.stop="hasSelected = false">Atrás</vs-button>
															<vs-button id="pm_mex_7_partner_structure_confirm_btn" color="black"
																@click.stop="saveBusinessData()">Continuar</vs-button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<!-- END CONTENT DIV -->
									</div>
									<!-- END MAIN-DIV -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import StakeholderForm from '@views/applicant/loan-requests/components/shared_components/StakeholderForm.vue';
const stakeholders_options = [
	{ label: "1", value: "1", desc: null },
	{ label: "2", value: "2", desc: null },
	{ label: "3", value: "3", desc: null },
	{ label: "4 o más", value: "4", desc: null },
];

export default {
	props: ["onboardingStepData"],
	components: {
		StakeholderForm
	},
	data() {
		return {
			colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
			colClassFull: "vx-col w-full mb-5",
			requiredRules: "required",
			requiredPlaceholder: "(Requerido)",
			stakeholders_options: stakeholders_options,
			stakeholdersValue: null,
			hasSelected: false,
			check_legal: false,
			stakeholders: [
				{
					role_name: "Representante legal",
					role: "legal_rep",
					profile: null,
					email: null,
					isValid: true,
					is_max_percentage: false,
				}
			],
		};
	},
	computed: {
		currentOnboardingStep() {
			return this.onboardingStepData.onboarding_step;
		},
		currentStakeholders() {
			return this.onboardingStepData.business_stakeholders != null ? this.onboardingStepData.business_stakeholders : [];
		},
		rep_legal_is_main_user() {
			return true;
		},
		hasMoreThanFourStakeholders() {
			return this.stakeholdersValue > 4;
		},
		currentSharedValue() {
			let shared = 0;

			this.stakeholders.forEach((stakeholder) => {
				shared += stakeholder.share_percentage
			});

			return shared;
		},
	},
	methods: {
		onChangeRepLegal(e) {
			console.log(e);

			if (e) { // Sí es true, se debe eliminar un stakeholder porque el replegal es accionista
				if (this.stakeholders.length > 1) { //Sólo es el rep legal, no se debería eliminar
					this.stakeholders.pop();
				}
			} else { // Sino,  se debe agregar un stakeholder porque el replegal es accionista
				let stakeholderNum = this.stakeholdersValue == 1 ? 0 : 1;
				this.stakeholders.push({
					role_name: `Accionista ${this.stakeholders.length + stakeholderNum}`,
					role: `stakeholder_${this.stakeholders.length + stakeholderNum}`,
					profile: {
						first_name: null,
						second_name: null,
						last_name_1: null,
						last_name_2: null,
						curp: null,
						rfc: null,
						birth_date: null,
						citizenship: 700,
						phone: {
							phone: null,
							country_calling_code: "+52",
						},
						phone_id: null,
					},
					share_percentage: null,
					role_type: null,
					email: null,
					person_type: null,
					isValid: true,
					is_max_percentage: null,
					id: null
				});
			}
		},
		async selectStakeholders() {
			if (this.stakeholdersValue) {
				this.stakeholders = [
					{
						role_name: "Representante legal",
						role: "legal_rep",
						profile: this.onboardingStepData.business_personal,
						email: this.user.email,
						share_percentage: null,
						isValid: true,
						is_max_percentage: true,
						personal_profile_id: this.onboardingStepData.business_personal.id,
						id: null
					}];

				this.setStakeholders();

				this.hasSelected = true;
			} else {
				this.showLoading(false);
				this.missingFieldsNotif(null, "Selecciona primero cuantos accionistas tiene la empresa.");
			}
		},
		backStakeHolders() {
			this.hasSelected = false;
		},
		setStakeholders() {
			let step = 1;
			// Sí ya tiene stakeholders
			if (this.hasStakeHolders) {
				this.currentStakeholders.forEach((stakeholder, index) => {
					let personType = stakeholder.stakeholder_pp_id != null ? "1" : "2";
					this.stakeholders.push({
						role_name: "Accionista " + (index + 2),
						role: "stakeholder_" + (index + 2),
						profile: personType == "1" ? stakeholder.personal_profile : stakeholder.business_profile,
						share_percentage: stakeholder.share_percentage * 10,
						email: stakeholder.email,
						person_type: personType,
						isValid: true,
						is_max_percentage: false,
						id: stakeholder.id
					});
				});
			}

			let currentStakeholdersNum = this.currentStakeholders.length + 1;

			if (this.stakeholdersValue <= 4) { // Sí es mayor a 4 entonces no se suman más.
				let newStakeholdersValue = this.stakeholdersValue - currentStakeholdersNum;
				let numToSum = this.hasStakeHolders ? 2 : 1;
				if (newStakeholdersValue > 0) {
					for (let i = 0; i < newStakeholdersValue; i++) {
						this.stakeholders.push({
							role_name: "Accionista " + (i + step + numToSum),
							role: "stakeholder_" + (i + step + numToSum),
							profile: {
								first_name: null,
								second_name: null,
								last_name_1: null,
								last_name_2: null,
								curp: null,
								rfc: null,
								birth_date: null,
								citizenship: 700,
								phone: {
									phone: null,
									country_calling_code: "+52",
								},
								phone_id: null,
							},
							share_percentage: null,
							role_type: null,
							email: null,
							person_type: null,
							isValid: true,
							is_max_percentage: null,
							id: null

						});
					}
				}
			} else {
				if (!this.hasStakeHolders) {
					this.stakeholders.push({
						role_name: "Accionista 2",
						role: "stakeholder_2",
						profile: {
							first_name: null,
							second_name: null,
							last_name_1: null,
							last_name_2: null,
							curp: null,
							rfc: null,
							birth_date: null,
							citizenship: 700,
							phone: {
								phone: null,
								country_calling_code: "+52",
							},
							phone_id: null,
						},
						share_percentage: null,
						role_type: null,
						email: null,
						person_type: null,
						isValid: true,
						is_max_percentage: null,
						id: null

					});
				}
			}
		},
		async saveBusinessData() {
            const flags = [];
            let flag = false;
            const forms = this.$refs.stakeholderForm;
            for (let index = 0; index < forms.length; index++) {
                const element = forms[index];
                flag = await element.saveDataForm();
                flags.push(flag);
            }

            if (flags.includes(false)) {
                this.missingFieldsNotif();
                return;
            }

            if(this.currentSharedValue > 100) {
                this.missingFieldsNotif(null, "El porcentaje de acciones no puede superar el 100%");
                return;
            }

            if(!this.check_legal) {
                this.missingFieldsNotif(null, "Debes confirmar que los datos registrados son verdaderos y coinciden con la información actual de la empresa (Asamblea, Acta Constitutiva, etc.)");
                return;
            }

            this.saveDataForm();
        },
		async saveDataForm() {
			this.errorMssg = null;
			this.showLoading(true, "Guardando información...");
			try {
				let payload = {
					stakeholders: this.stakeholders
				};
				await axios.post(
					"/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
					payload
				);
				this.showLoading(false);
				this.$emit("updated", 1);
			} catch (e) {
				this.showLoading(false);
				this.showError(e);
			}
		},
		hasError(val) {
			return this.errors.has(val);
		},
		errorText(val) {
			return this.errors.first(val);
		},
		isSuccess(val) {
			let ff = this.fields[val];
			return ff && ff.required && ff.valid;
		},
	},
};
</script>
<style>
.vs-collapse-item {
    border: 1px solid #28de18 !important;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.vs-collapse-item.danger {
    border: 1px solid rgba(var(--vs-danger),1) !important;
}
#stakeholder-form .vs-collapse-item.open-item .vs-collapse-item--content {
    max-height: fit-content !important;
}
</style>