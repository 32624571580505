<template>
  <div id="pm_mex_4_legal_rep_data_check_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Confirma los datos del representante legal para continuar</p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                          <div :class="colClassFull">
                              <label>Nombre</label>
                              <vs-list v-if="!isNameFormVisible">
                                <vs-list-item :title="currentFullname">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(1)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClass">
                                    <vs-input
                                      @input="(val) => (currentFirstName = currentFirstName.toUpperCase())"
                                      class="w-full"
                                      label="Primer nombre *"
                                      name="currentFirstName"
                                      v-validate="requiredRules"
                                      v-model.lazy="currentFirstName"
                                      :danger="hasError('currentFirstName')"
                                      :danger-text="errorText('currentFirstName')"
                                      :success="isSuccess('currentFirstName')"
                                      icon-pack="feather"
                                      :placeholder="requiredPlaceholder"
                                    />
                                  </div>
                                  <div :class="colClass">
                                    <vs-input
                                      @input="(val) => (currentSecondName = currentSecondName.toUpperCase())"
                                      class="w-full"
                                      label="Segundo nombre"
                                      name="currentSecondName"
                                      v-model.lazy="currentSecondName"
                                      :danger="hasError('currentSecondName')"
                                      :danger-text="errorText('currentSecondName')"
                                      :success="isSuccess('currentSecondName')"
                                      icon-pack="feather"
                                      :placeholder="optionalPlaceholder"
                                    />
                                  </div>
                                  <div :class="colClass">
                                    <vs-input
                                      @input="(val) => (currentLastName1 = currentLastName1.toUpperCase())"
                                      class="w-full"
                                      label="Primer apellido *"
                                      name="currentLastName1"
                                      v-validate="requiredRules"
                                      v-model.lazy="currentLastName1"
                                      :danger="hasError('currentLastName1')"
                                      :danger-text="errorText('currentLastName1')"
                                      :success="isSuccess('currentLastName1')"
                                      icon-pack="feather"
                                      :placeholder="requiredPlaceholder"
                                    />
                                  </div>
                                  <div :class="colClass">
                                    <vs-input
                                      @input="(val) => (currentLastName2 = currentLastName2.toUpperCase())"
                                      class="w-full"
                                      label="Segundo apellido"
                                      name="currentLastName2"
                                      v-model.lazy="currentLastName2"
                                      :danger="hasError('currentLastName2')"
                                      :danger-text="errorText('currentLastName2')"
                                      :success="isSuccess('currentLastName2')"
                                      icon-pack="feather"
                                      :placeholder="optionalPlaceholder"
                                    />
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                          <div :class="colClassFull">
                              <label>Género</label>
                              <vs-list v-if="!isGenderFormVisible">
                                <vs-list-item :title="currentGenderStr">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(2)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClass" v-for="gender in genderOptions" :key="gender.value">
                                      <rg-radio class="w-full" groupName="gender" :label="gender.label" :value="gender.value" v-model="currentGender" />
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                          <div :class="colClassFull">
                              <label>Fecha de nacimiento</label>
                              <vs-list v-if="!isBirthDateFormVisible">
                                <vs-list-item :title="regularDateFormat(currentBirthDate)">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(3)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClassFull">
                                    <datepicker
                                      class="w-full"
                                      v-model.lazy="currentBirthDate"
                                      name="currentBirthDate"
                                      :language="lang_es"
                                      v-validate="requiredRules"
                                      :danger="hasError('currentBirthDate')"
                                      :danger-text="errorText('currentBirthDate')"
                                      :success="isSuccess('currentBirthDate')"
                                      format="dd/MM/yyyy"
                                      initial-view="year"
                                      :disabled-dates="disabledDates"
                                      :placeholder="requiredPlaceholder"
                                      :use-utc="true"
                                    />
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                          <div :class="colClassFull">
                              <label>Estado de nacimiento</label>
                              <vs-list v-if="!isBirthStateFormVisible">
                                <vs-list-item :title="currentBirthStateStr">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(4)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClassFull">
                                    <v-select
                                      class="vs-custom"
                                      name="currentBirthState"
                                      :class="{
                                        'has-error': hasError('currentBirthState'),
                                        'is-success': isSuccess('currentBirthState'),
                                      }"
                                      v-model.lazy="currentBirthState"
                                      v-validate="requiredRules"
                                      :options="collections.states"
                                      :reduce="(item) => item.value"
                                      :clearable="false"
                                      placeholder="Selecciona una opción"
                                      :searchable="false"
                                    >
                                    </v-select>
                                    <v-error v-if="hasError('currentBirthState')" :error="errorText('currentBirthState')" />
                                  </div>
                                </div>
                                <div v-if="currentBirthState == birthStateIdForeign" class="vx-row">
                                  <div class="vx-col w-full mt-5">
                                    <label class="vs-input--label"
                                      >País de nacimiento *</label
                                    >
                                    <v-select
                                      class="vs-custom"
                                      name="currentOriginCountry"
                                      :class="{
                                        'has-error': hasError('currentOriginCountry'),
                                        'is-success': isSuccess('currentOriginCountry'),
                                      }"
                                      v-model.lazy="currentOriginCountry"
                                      v-validate="requiredRules"
                                      :options="collections.countries"
                                      :reduce="(item) => item.value"
                                      :clearable="false"
                                      placeholder="Selecciona una opción"
                                      :searchable="false"
                                    >
                                    </v-select>
                                    <v-error v-if="hasError('currentOriginCountry')" :error="errorText('currentOriginCountry')" />
                                  </div>
                                  <div class="vx-col w-full mt-5">
                                    <label class="vs-input--label"
                                      >Nacionalidad *</label
                                    >
                                    <v-select
                                      class="vs-custom"
                                      name="currentCitizenship"
                                      :class="{
                                        'has-error': hasError('currentCitizenship'),
                                        'is-success': isSuccess('currentCitizenship'),
                                      }"
                                      v-model.lazy="currentCitizenship"
                                      v-validate="requiredRules"
                                      :options="collections.countries"
                                      :reduce="(item) => item.value"
                                      :clearable="false"
                                      placeholder="Selecciona una opción"
                                      :searchable="false"
                                    >
                                    </v-select>
                                    <v-error v-if="hasError('currentCitizenship')" :error="errorText('currentCitizenship')" />
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                          <div :class="colClassFull">
                              <label>RFC</label>
                              <vs-list v-if="!isRfcFormVisible">
                                <vs-list-item :title="currentRfc">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(5)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClassFull">
                                    <vs-input
                                      @input="(val) => (currentRfc = currentRfc.toUpperCase())"
                                      class="w-full"
                                      name="currentRfc"
                                      v-validate="requiredRules"
                                      v-model.lazy="currentRfc"
                                      :danger="hasError('currentRfc')"
                                      :danger-text="errorText('currentRfc')"
                                      :success="isSuccess('currentRfc')"
                                      icon-pack="feather"
                                      :placeholder="requiredPlaceholder"
                                    />
                                    <a
                                      class="inline-link-primary"
                                      target="_blank"
                                      rel="noopener"
                                      :href="$sanitizeUrl(rfcUrl)"
                                      >Consultar mi RFC</a
                                    >
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                          <div :class="colClassFull">
                              <label>CURP</label>
                              <vs-list v-if="!isCurpFormVisible">
                                <vs-list-item :title="currentCurp">
                                  <vs-button v-if="!hasIdentityVerification" color="dark" type="flat" icon-pack="feather" icon="icon-edit" @click="showStepForm(6)"></vs-button>
                                </vs-list-item>
                              </vs-list>
                              <template v-else>
                                <div class="vx-row">
                                  <div :class="colClassFull">
                                    <vs-input
                                      @input="(val) => (currentCurp = currentCurp.toUpperCase())"
                                      class="w-full"
                                      name="currentCurp"
                                      v-validate="requiredRules"
                                      v-model.lazy="currentCurp"
                                      :danger="hasError('currentCurp')"
                                      :danger-text="errorText('currentCurp')"
                                      :success="isSuccess('currentCurp')"
                                      icon-pack="feather"
                                      :placeholder="requiredPlaceholder"
                                    />
                                    <a
                                      class="inline-link-primary"
                                      target="_blank"
                                      rel="noopener"
                                      :href="$sanitizeUrl(curpUrl)"
                                      >Consultar mi CURP</a
                                    >
                                  </div>
                                </div>
                              </template>
                              <vs-divider color="dark"></vs-divider>
                          </div>
                      </div>
                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <div class="vx-row mb-5 mt-3">
                          <div :class="colClassFull">
                              <vs-alert
                                v-if="hasIdentityVerification"
                                icon-pack="feather"
                                icon="icon-info"
                                style="height:auto"
                                color="rgb(41, 147, 138)">
                                    Ya contamos con tu verificación de identidad, en caso de que alguno de los datos
                                    <strong>no sean tuyos o estén equivocados, </strong>
                                    ponte en <strong>contacto</strong> con nosotros.
                              </vs-alert>
                              <vs-alert
                                v-else
                                icon-pack="feather"
                                icon="icon-info"
                                style="height:auto"
                                color="rgb(41, 147, 138)">
                                    Por ley, en caso de que alguno de los datos
                                    <strong>no sean tuyos o estén equivocados, </strong>
                                    desafortunadamente deberemos
                                    <strong>bloquear tu cuenta.</strong>
                              </vs-alert>
                          </div>
                      </div>
                      <div class="vx-row">
                          <div :class="colClassFull">
                              <vs-button id="pm_mex_4_legal_rep_data_check_confirm_btn" size="large" class="w-full" @click="saveDataForm">
                                <template v-if="hasIdentityVerification">
                                  Continuar
                                </template>
                                <template v-else>
                                  Confirmar mis datos
                                </template>
                              </vs-button>
                          </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->

                  <!-- CONFIRMATION-DIV -->
                  <!-- <div v-else class="px-6 pt-5 pb-5">
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Hemos recibido tu información y estamos verificando tus datos.</p>
                      </div>
                    </div>
                    <div class="main-form">
                      <div class="vx-row">
                          <div :class="colClassFull">
                              <p>
                                Como parte de nuestro compromiso con tu seguridad, a continuación te presentamos los riesgos que existen al invertir con nosotros.
                              </p>
                          </div>
                      </div>
                      <div class="vx-row mt-base">
                          <div :class="colClassFull">
                              <vs-button size="large" class="w-full" @click="$emit('updated', 1)">Continuar</vs-button>
                          </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- END CONFIRMATION-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from "@mixins/dateHelper";
import { es } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

export default {
  mixins: [inputHelper, dateHelper],
  props: ["onboardingStepData"],
  components: {
    Datepicker
  },
  data() {
    return {
      mexicoId: 700,
      rfcUrl: "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
      curpUrl: "https://www.gob.mx/curp/",
      lang_es: es,
      collections: {
        states: [],
        countries: [],
      },
      disabledDates: {
        from: new Date(),
      },
      requiredRules: "required",
      errorMssg: null,
      colClassFull: "vx-col w-full",
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: "(Opcional)",
      genderOptions: [
        {"value": 1, "label": "HOMBRE"},
        {"value": 2, "label": "MUJER"}
      ],
      currentFirstName: null,
      currentSecondName: null,
      currentLastName1: null,
      currentLastName2: null,
      currentGender: null,
      currentBirthDate: null,
      currentBirthState: null,
      currentOriginCountry: null,
      currentCitizenship: null,
      currentRfc: null,
      currentCurp: null,
      isNameFormVisible: false,
      isGenderFormVisible: false,
      isBirthDateFormVisible: false,
      isBirthStateFormVisible: false,
      isRfcFormVisible: false,
      isCurpFormVisible: false,
      // showConfirmationCard:false
      birthStateIdForeign:null
    };
  },
  mounted(){
    this.currentFirstName = this.personalProfile.first_name;
    this.currentSecondName = this.personalProfile.second_name;
    this.currentLastName1 = this.personalProfile.last_name_1;
    this.currentLastName2 = this.personalProfile.last_name_2;
    this.currentGender = this.personalProfile.gender;
    this.currentBirthDate = this.personalProfile.birth_date;
    this.currentBirthState = this.personalProfile.birth_state;
    this.currentOriginCountry = this.personalProfile.origin_country;
    this.currentCitizenship = this.personalProfile.citizenship;
    this.currentRfc = this.personalProfile.rfc;
    this.currentCurp = this.personalProfile.curp;
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    personalProfile() {
      return this.onboardingStepData.business_personal;
    },
    currentFullname() {
      return this.onboardingStepData.full_name;
    },
    currentGenderStr() {
      return this.personalProfile.gender == 1? "HOMBRE" : "MUJER";
    },
    currentBirthStateStr() {
      return this.personalProfile.birth_state_named;
    },
    hasIdentityVerification() {
      return !(this.onboardingStepData.identity_verification_status == null || this.onboardingStepData.identity_verification_status != 1);
    }
  },
  methods: {
    async showStepForm(option) {
      if (this.hasIdentityVerification) {
        return;
      }
      switch (option) {
        case 1:
          this.isNameFormVisible = true;
          break;
        case 2:
          this.isGenderFormVisible = true;
          break;
        case 3:
          this.isBirthDateFormVisible = true;          
          break;
        case 4:
          this.showLoading(true);
          await this.getCollections();
          this.showLoading(false);
          this.isBirthStateFormVisible = true;
          break;
        case 5:
          this.isRfcFormVisible = true;
          break;
        case 6:
          this.isCurpFormVisible = true;
          break;
        default:
          break;
      }
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'statesList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        colls.statesList.forEach(opt => {
          if (opt.name == "EXTRANJERO (FOREIGN)") {
            this.birthStateIdForeign = parseInt(opt.id);
          }
          this.collections.states.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
        colls.countriesList.forEach(opt => {
          this.collections.countries.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        if (this.currentBirthState != this.birthStateIdForeign) {
          this.currentOriginCountry = this.mexicoId;
          this.currentCitizenship = this.mexicoId;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          first_name: this.currentFirstName,
          second_name: this.currentSecondName,
          last_name_1: this.currentLastName1,
          last_name_2: this.currentLastName2,
          gender: this.currentGender,
          birth_state: this.currentBirthState,
          birth_date: this.currentBirthDate,
          origin_country: this.currentOriginCountry,
          citizenship: this.currentCitizenship,
          rfc: this.currentRfc,
          curp: this.currentCurp,
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
        // this.showConfirmationCard=true;
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>

<style scoped>
.vs-list {
    padding: 0px !important;
}
.vs-list--item {
    padding: 0px !important;
}
</style>