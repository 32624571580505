<template>
  <div id="7_occupation_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">¿A qué te dedicas?</p>
                        <p>Selecciona la opción que más te identifique</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <label class="vs-input--label bold">Ocupación *</label>
                      <v-error v-if="errorMssg && !personalOccupationId" error="Este campo es obligatorio" />
                      <div class="vx-row mt-2">
                          <div :class="colClass" v-for="occupation in collections.occupations" :key="occupation.value">
                              <rg-radio class="w-full" groupName="occupations" :label="occupation.label" :value="occupation.value" v-model="personalOccupationId" />
                          </div>
                      </div>
                      <template v-if="showSectorActivityOptions">
                        <label class="vs-input--label bold">Sector *</label>
                        <v-error v-if="errorMssg && !personalBusinessActivityCategoryId" error="Este campo es obligatorio" />
                        <div class="vx-row mt-2">
                            <div :class="colClass" v-for="sector in collections.personalBusinessActivitiesCategories" :key="sector.value">
                                <rg-radio v-if="sector.value != noSectorCategoryId" class="w-full" groupName="personalBusinessActivitiesCategories" :label="sector.label" :value="sector.value" v-on:change="setActivities" v-model="personalBusinessActivityCategoryId" />
                            </div>
                        </div>
                        <div :class="colClassFull">
                          <label class="vs-input--label bold"
                            >Actividad principal *</label
                          >
                          <v-select
                            class="vs-custom"
                            name="personalBusinessActivityId"
                            :class="{
                              'has-error': hasError('personalBusinessActivityId'),
                              'is-success': isSuccess('personalBusinessActivityId'),
                            }"
                            v-model.lazy="personalBusinessActivityId"
                            v-validate="requiredRules"
                            :options="collections.personalBusinessActivities"
                            :reduce="(item) => item.value"
                            :clearable="false"
                            :disabled="!personalBusinessActivityCategoryId"
                            placeholder="Selecciona una opción"
                            :searchable="false"
                          >
                          </v-select>
                          <v-error v-if="hasError('personalBusinessActivityId')" :error="errorText('personalBusinessActivityId')" />
                        </div>
                      </template>
                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="7_occupation_confirm_btn" class="w-full" @click="saveDataForm"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  mixins: [inputHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      noSectorCategoryId: 7,
      housewifeActivityId: 100,
      studentActivityId: 101,
      retiredActivityId: 102,
      collections: {
        occupations: [],
        personalBusinessActivitiesCategories: [],
        personalBusinessActivities: [],
      },
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      personalOccupationId: null,
      personalBusinessActivityCategoryId: null,
      personalBusinessActivityId: null
    };
  },
  async beforeMount(){
    this.showLoading(true);
    await this.getCollections();
    this.personalOccupationId = this.onboardingStepData.personal_occupation_id;
    this.personalBusinessActivityId = this.onboardingStepData.personal_business_activity_id;
    this.setPersonalBusinessActivitiesCategory();
    this.showLoading(false);
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    showSectorActivityOptions() {
      return (this.personalOccupationId != null && this.personalOccupationId != 4 && this.personalOccupationId != 5 && this.personalOccupationId != 6);
    }
  },
  methods: {
    setPersonalBusinessActivitiesCategory() {
      let personalBusinessActivityId = this.personalBusinessActivityId;
      if (personalBusinessActivityId == null || personalBusinessActivityId == "") {
        return;
      }
      let match = null;
      let categorySelected = null;
      for (const category of this.collections.personalBusinessActivitiesCategories) {
        match = category.activities.find(x => x.id === personalBusinessActivityId);
        if (match != undefined) {
          categorySelected = category;
          categorySelected.activities.forEach(opt => {
            this.collections.personalBusinessActivities.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
          })
          this.personalBusinessActivityCategoryId = categorySelected.value;
          break;
        }
      }
    },
    async getCollections(){
      try {
        let collectionsObjects = ['personalOccupationsList', 'personalBusinessActivityCategoryList'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
        let colls = res.data;
        colls.personalOccupationsList.forEach(opt => {
          this.collections.occupations.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
        })
        colls.personalBusinessActivityCategoryList.forEach(opt => {
          this.collections.personalBusinessActivitiesCategories.push({value: parseInt(opt.id), label: opt.name, activities: opt.activities}); //conversion a sintaxis de vx-select
        })
      }
      catch (e) {
      }
    },
    setActivities(){
      if (this.personalBusinessActivityCategoryId == null || this.personalBusinessActivityCategoryId == "") {
        return;
      }
      let categorySelected = this.collections.personalBusinessActivitiesCategories.find(x => x.value == this.personalBusinessActivityCategoryId);
      this.collections.personalBusinessActivities = [];
      categorySelected.activities.forEach(opt => {
        this.collections.personalBusinessActivities.push({value: parseInt(opt.id), label: opt.name}); //conversion a sintaxis de vx-select
      })
      this.personalBusinessActivityId = null;
    },
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        let customPersonalBusinessActivityId = this.personalBusinessActivityId;
        this.personalOccupationId = parseInt(this.personalOccupationId);
        switch (this.personalOccupationId) {
          case 4:
            customPersonalBusinessActivityId = this.studentActivityId;
            break;
          case 5:
            customPersonalBusinessActivityId = this.retiredActivityId;
            break;
          case 6:
            customPersonalBusinessActivityId = this.housewifeActivityId;
            break;
          default:
            break;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          personal_occupation_id: this.personalOccupationId,
          personal_business_activity_id: customPersonalBusinessActivityId
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      let complete = false;
      if (this.$validator.validateAll()) {
        if (this.showSectorActivityOptions) {
          if (this.personalOccupationId != null && this.personalBusinessActivityCategoryId != null && this.personalBusinessActivityId != null ) {
            complete = true;
          } else {
            this.errorMssg = "Debes completar todos los campos";
            setTimeout(() => this.errorMssg = null, 5000);
          }          
        } else {
          if (this.personalOccupationId != null) {
            complete = true;
          } else {
            this.errorMssg = "Debes completar todos los campos";
            setTimeout(() => this.errorMssg = null, 5000);
          }
        }
      }
      return complete;
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
      setTimeout(() => this.errorMssg = null, 5000);
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
