<template>
  <div v-if="isMounted">
      <div v-if="!onboardingStepData.is_new_investor" class="vx-row no-gutter items-center justify-center">
        <div v-if="showWelcomeMessage" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
          <div class="vx-row no-gutter items-center justify-center p-6">
            <vx-card class="login-card py-3 px-3">
              <div slot="no-body" class="">
                <div class="vx-col sm:w-full">
                  <div class="login-tabs-container">
                    <div class="vx-row m-0">
                      <div class="vx-col w-full p-0" style="min-height: 200px">
                        <!-- MAIN-DIV -->
                        <div class="px-6 pt-5 pb-5">
                          <!-- HEADER -->
                          <div class="vx-row mb-4">
                            <div class="vx-col w-full">
                              <p class="text-2xl card-sub-title">¡Te damos la bienvenida!</p>
                            </div>
                          </div>
                          <vs-divider color="dark"></vs-divider>
                          <!-- CONTENT -->
                          <div class="main-form mt-base">
                            <div class="vx-row">
                              <div :class="colClassFull">
                                <p>
                                  Por seguridad de tu cuenta y cumplimiento regulatorio, necesitamos que nos ayudes a actualizar tu expediente.
                                  <br>
                                  <br>
                                  <strong>¡Gracias!</strong>
                                </p>
                              </div>
                            </div>
                            <div class="vx-row">
                              <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                              <div class="vx-col md:w-1/2 sm:w-full w-full">
                                <vs-button class="w-full mt-4" @click="showWelcomeMessage=false"
                                  >Actualizar datos</vs-button
                                >
                              </div>
                            </div>
                          </div>
                          <!-- END CONTENT DIV -->
                        </div>
                        <!-- END MAIN-DIV -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </div>
      <div v-if="!showWelcomeMessage">
        <div class="vx-row no-gutter items-center justify-center">
          <div class="vx-col w-full">
            <investor-main-onboarding-step-page-pm v-if="isMoral" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-main-onboarding-step-page-pf v-else :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
          </div>
        </div>
        <div class="vx-row no-gutter items-center justify-center">
          <investor-onboarding-referal-banner v-if="showReferalBanner" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
        </div>
      </div>
  </div>
</template>

<script>
import InvestorMainOnboardingStepPagePf from "@views/investor/onboarding/InvestorMainOnboardingStepPagePf.vue";
import InvestorMainOnboardingStepPagePm from "@views/investor/onboarding/InvestorMainOnboardingStepPagePm.vue";
import InvestorOnboardingReferalBanner from "@views/investor/onboarding/InvestorOnboardingReferalBanner.vue";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
  name: "InvestorOnboardingStepPage",
  mixins: [investorInfoHelper],
  components: {
    InvestorMainOnboardingStepPagePf,
    InvestorMainOnboardingStepPagePm,
    InvestorOnboardingReferalBanner
  },
  async mounted(){
    this.isMounted = false;
    await this.getInvestorOnboardingData();
    if (this.onboardingStepData.is_new_investor) {
      this.showWelcomeMessage = false;
    }
    this.isMounted = true;
  },
  data(){
    return {
      isMounted: false,
      showWelcomeMessage: true,
      colClassFull: "vx-col w-full mb-5",
      onboardingStepData: {
        onboarding_step: null,
        phone_number: null,
        rfc: null,
        curp: null,
      }
    }
  },
  computed: {
    currentOnboardingStep(){
      return this.onboardingStepData.onboarding_step;
    },
    showReferalBanner(){
      return this.currentOnboardingStep == '2_cellphone_checked' 
      || this.currentOnboardingStep == '3_name'
      || this.currentOnboardingStep == '4_birth_data' 
      || this.currentOnboardingStep == '5_gender'
      || this.currentOnboardingStep == '6_rfc_curp'
      || this.currentOnboardingStep == '7_occupation'
      || this.currentOnboardingStep == 'pm_mex_2_cellphone_checked'
      || this.currentOnboardingStep == 'pm_mex_3_legal_rep_name'
      || this.currentOnboardingStep == 'pm_mex_4_legal_rep_data_check'
      || this.currentOnboardingStep == 'pm_mex_5_name_address'
    },
    isMoral(){
      return this.userIsMoral(this.UserPersonType);
    }
  },
  methods: {
    async getInvestorOnboardingData(){
      try {
        const response = await axios.get('/api/v2/investor/' + this.InvestorId + '/getOnboardingData');
        if (response.data.onboarding_step == null || response.data.onboarding_step == this.InvestorOnboardingLastStepPF || response.data.onboarding_step == this.InvestorOnboardingLastStepPM) {
          this.user.investor_onboarding_step = response.data.onboarding_step;
          this.$router.replace({name: 'inicioInversionista'});
        } else {
          this.onboardingStepData = response.data;
        }
      }
      catch (e) {
        this.error_message = 'Ocurrió  un error al obtener los datos, inténtalo mas tarde.'
      }
    }
  }
}
</script>