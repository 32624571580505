<template>
	<div id="pm_mex_9_documents_id_verification_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
		<div class="vx-row no-gutter items-center justify-center p-6">
			<vx-card class="login-card py-3 px-3">
				<div slot="no-body" class="">
					<div class="vx-col sm:w-full">
						<div class="login-tabs-container">
							<div class="vx-row m-0">
								<div class="vx-col w-full p-0" style="min-height: 200px">
									<!-- MAIN-DIV -->
									<div class="px-6 pt-5 pb-5">
										<div v-if="mainMenuOption == 0">
											<div class="vx-row">
												<div class="vx-col w-full">
													<h2>Documentos requeridos</h2>
													<vs-divider></vs-divider>
												</div>
											</div>
											<div class="vx-row mb-4 m-1">
												<div class="vx-col w-full border-bottom-green clickable-img cursor-pointer"
													@click.stop="mainMenuOption = 1">
													<div class="flex justify-between mb-3 p-0 mt-4">
														<div class="mr-4">
															<h5>Información de representante legal.</h5>
															<p class="mb-2">Identificación oficial</p>
														</div>
														<div class="flex align-self-center">
															<vs-chip class="mt-1" size="small"
																:color="identityVerificationStatus ? 'success' : 'warning'">{{
																	identityVerificationStatus ? 'Completado' : 'Pendiente' }}</vs-chip>
															<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																class="cursor-pointer" />
														</div>
													</div>
												</div>
											</div>
											<div class="vx-row mb-base m-1">
												<div class="vx-col w-full border-bottom-green cursor-pointer"
													@click.stop="mainMenuOption = 2">
													<div class="flex justify-between mb-3 p-0 mt-4">
														<div class="mr-4">
															<h5>Información de la empresa</h5>
															<p class="mb-2">Documentos que la constituyen</p>
														</div>
														<div class="flex align-self-center">
															<vs-chip class="mt-1" size="small" :color="businessData().color">{{
																businessData().status }}</vs-chip>
															<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																class="cursor-pointer" />
														</div>
													</div>
												</div>
											</div>
											<div class="vx-row mt-base">
												<div class="vx-col md:w-1/2 sm:w-full w-full"></div>
												<div class="vx-col md:w-1/2 sm:w-full w-full">
													<vs-button
														:disabled="!businessDataIsCompleted || identityVerificationStatus == null"
														id="pm_mex_9_documents_id_verification_confirm_btn" class="w-full"
														@click="saveDataForm">Continuar</vs-button>
												</div>
											</div>
										</div>
										<div v-if="mainMenuOption == 1">
											<div class="vx-row">
												<div class="vx-col w-full">
													<h2>Información de representante legal</h2>
													<vs-divider></vs-divider>
												</div>
											</div>
											<template v-if="menuOption == 0">
												<div class="vx-row mb-base m-1">
													<div class="vx-col w-full border-bottom-green cursor-pointer"
														@click.stop="menuOption = 1">
														<div
															class="flex justify-between mb-4 p-0 mt-4 border-bottom-green cursor-pointer">
															<div class="mr-4">
																<h5>INE</h5>
																<p class="mb-2">Verificación de identidad</p>
															</div>
															<div class="flex align-self-center">
																<vs-chip class="mt-1" size="small" :color="getIdentityData().color">{{
																	getIdentityData().status }}</vs-chip>
																<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																	class="cursor-pointer" />
															</div>
														</div>
													</div>
												</div>
												<div class="vx-row">
													<div class="vx-col sm:w-1/2 md:w-1/12">
														<vs-button size="sm" color="black" type="border"
															@click.stop="mainMenuOption = 0">Atrás</vs-button>
													</div>
												</div>
											</template>
											<template v-if="menuOption == 1">
												<div class="vx-row mt-5"
													v-if="(mainComplianceStatus === 'running' && !investorHasOperations) && isMounted">
													<div class="vx-col w-full h-auto">
														<vx-card title="Hemos recibido tu información.">
															<p style="line-height: 1.5rem" class="text-md">
																Ya falta muy poco para que puedas abonar fondos y realizar ¡tu primera
																inversión de impacto en RedGirasol!
																Te pedimos estar atento a tu correo electrónico para recibir la
																confirmación de que tu verificación ha sido completada.
																Este proceso puede tomar aproximadamente entre 5 y 10 minutos, y en
																algunos casos hasta 12 horas.
																Si tienes alguna duda sobre el proceso de verificación estamos para
																apoyarte
																a través del chat de la plataforma o en cualquiera de nuestras redes
																sociales.
															</p>
														</vx-card>
													</div>
												</div>

												<div class="vx-row mt-5"
													v-if="(mainComplianceStatus === 'completed' && !investorHasOperations) && isMounted">
													<div class="vx-col w-full h-auto">
														<vx-card title="¡Ya puedes continuar!">
															<p style="line-height: 1.5rem" class="text-md">
																Tu cuenta en la Red está lista para que puedas continuar y realizar ¡tu
																primera inversión de impacto en RedGirasol!
															</p>
														</vx-card>
													</div>
												</div>

												<vx-card class="mt-5"
													v-if="(mainComplianceStatus === 'completed' && investorHasOperations) && isMounted">
													<div class="vx-row">
														<div class="vx-col w-full">
															<div class="mb-4">
																<div class="flex flex-wrap items-center">
																	<p class="card-sub-title mr-5">
																		Verificación de identidad.
																	</p>
																</div>
															</div>
															<p>
																<span class="">El proceso de verificación de identidad <span
																		class="bold text-primary">ha sido completado</span>.</span>
															</p>
														</div>
													</div>
												</vx-card>

												<vx-card class="mt-5"
													v-if="(mainComplianceStatus === 'running' && investorHasOperations) && isMounted">
													<div class="vx-row">
														<div class="vx-col w-full">
															<div class="mb-4">
																<div class="flex flex-wrap items-center">
																	<p class="card-sub-title mr-5">
																		Verificación de identidad.
																	</p>
																</div>
															</div>
															<p>
																<span class="bold">El proceso de verificación de identidad se encuentra
																	en proceso.</span>
																Vuelve en unos minutos para consultar si el proceso fue exitoso.
															</p>
														</div>
													</div>
												</vx-card>

												<vx-card class="mt-5" v-if="(mainComplianceStatus === 'pending') && isMounted">
													<div class="vx-row">
														<div class="vx-col w-full">
															<div class="mb-4">
																<div class="flex flex-wrap items-center">
																	<p class="card-sub-title mr-5"
																		v-if="mverficationNoSuccessful && !processWasStarted">
																		La verificación de identidad no fue exitosa.
																	</p>
																	<p class="card-sub-title mr-5" v-else>
																		Verificación de identidad.
																	</p>
																</div>
															</div>

															<template v-if="!processWasStarted">
																<p v-if="mProcessIsPending">
																	En la Red, es importante verificar que tus datos concuerden con tu
																	identidad.
																	Es por esto que utilizamos un software de reconocimiento de identidad
																	a través de un proceso rápido y sencillo.
																	Para iniciar con el proceso da clic en “Comenzar” y después lee con
																	calma las indicaciones que tenemos
																	para ti con el fin de que tu proceso sea exitoso.
																</p>
																<p v-if="mverficationNoSuccessful">
																	<span class="bold">El proceso de verificación de identidad no fue
																		exitoso.</span> Comienza nuevamente siguiendo las recomendaciones
																	al pie de la letra y asegúrate de concluir el proceso correctamente.
																	Principalmente cuida que tengas buena iluminación al momento de
																	grabar
																	tu rostro o que las fotografías de tus documentos sean legibles, es
																	decir, que no se vean borrosas o con reflejos que no nos permitan
																	leer la información claramente.
																</p>
															</template>
															<template v-else>
																<p>
																	En la Red, es importante verificar que tus datos concuerden con tu
																	identidad.
																	Es por esto que utilizamos un software de reconocimiento de identidad
																	a través de un proceso rápido y sencillo.
																	Para iniciar con el proceso da clic en “Comenzar” y después lee con
																	calma las indicaciones que tenemos
																	para ti con el fin de que tu proceso sea exitoso.
																</p>
															</template>
														</div>
													</div>

													<div class="vx-row mt-3" v-if="isMounted && processWasStarted">
														<div class="vx-col w-full">
															<vs-alert color="warning">
																<span class="bold">Estamos procesando tu información. Vuelve en unos
																	minutos para consultar si el proceso fue
																	exitoso.</span>
															</vs-alert>
														</div>
													</div>

													<div class="vx-row mt-3" v-if="isMounted && !processWasStarted">
														<!--div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsCompleted">
															<vs-alert color="success">Tu identidad ha sido verificada correctamente.</vs-alert>
														</div>
														<div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsRejected">
															<vs-alert color="warning">Lamentablemente no ha sido posible verificar tu identidad, por favor inténtalo de nuevo o contáctanos a través del chat.</vs-alert>
														</div>
														<div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsInProcess">
															<vs-alert color="primary">La verificación de tu identidad se encuentra en proceso.</vs-alert>
														</div>
														<div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationIsReviewNeeded">
															<vs-alert color="primary">La verificación de tu identidad se encuentra en proceso.</vs-alert>
														</div>
														<div class="vx-col xxl:w-1/2 lg:w-2/3 w-full mt-2 mb-3" v-if="mverificationisNewRequired">
															<vs-alert color="primary">El proceso de verificación de identidad no fue exitoso, por favor inténtalo de nuevo.</vs-alert>
														</div-->
														<div class="vx-col w-full" v-if="mshouldRunMatiProcess">
															<vs-button class="ml-auto mt-2" @click="openMatiPopup"
																v-if="mverficationNoSuccessful">Comenzar
																nuevamente</vs-button>
															<vs-button class="ml-auto mt-2" @click="openMatiPopup"
																v-else>Comenzar</vs-button>
														</div>
													</div>
												</vx-card>

												<mati-popup :user-id-to-activate="UserId"
													@on-mati-process-started="onMatiProcessStarted" />

												<div class="vx-row mt-base">
													<div class="vx-col sm:w-1/2 md:w-1/12">
														<vs-button size="sm" color="black" type="border"
															@click.stop="menuOption = 0">Atrás</vs-button>
													</div>
												</div>
											</template>
										</div>
										<div v-if="mainMenuOption == 2">
											<div v-if="menuOption == 0">
												<div class="vx-row">
													<div class="vx-col w-full">
														<h2>Documentos requeridos</h2>
														<vs-divider></vs-divider>
													</div>
												</div>
												<div class="vx-row mb-base m-1">
													<div class="vx-col w-full border-bottom-green cursor-pointer"
														@click.stop="menuOption = 1">
														<div class="flex justify-between mb-3 p-0 mt-4">
															<div class="mr-4">
																<h5>Acta constitutiva</h5>
																<p class="mb-2">Debes anexar datos de inscripción correspondiente
																	(sellos y boletas).</p>
															</div>
															<div class="flex align-self-center">
																<vs-chip class="mt-1" size="small"
																	:color="documentData(constitutiveAct).color">{{
																		documentData(constitutiveAct).status }}</vs-chip>
																<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																	class="cursor-pointer" />
															</div>
														</div>
													</div>
													<div class="vx-col w-full border-bottom-green cursor-pointer"
														@click.stop="menuOption = 2">
														<div class="flex justify-between mb-3 p-0 mt-4">
															<div class="mr-4">
																<h5>Asamblea con estructura accionaria más reciente</h5>
																<p class="mb-2">*Documento opcional, incluirla si aplica.</p>
															</div>
															<div class="flex align-self-center">
																<vs-chip class="mt-1" size="small"
																	:color="documentData(shareholderMeeting).color">{{
																		documentData(shareholderMeeting).status }}</vs-chip>
																<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																	class="cursor-pointer" />
															</div>
														</div>
													</div>
													<div class="vx-col w-full border-bottom-green cursor-pointer"
														@click.stop="menuOption = 3">
														<div class="flex justify-between mb-3 p-0 mt-4">
															<div class="mr-4">
																<h5>Constancia de situación fiscal</h5>
																<p class="mb-2">No mayor a 3 meses..</p>
															</div>
															<div class="flex align-self-center">
																<vs-chip class="mt-1" size="small"
																	:color="documentData(fiscalRecord).color">{{
																		documentData(fiscalRecord).status }}</vs-chip>
																<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																	class="cursor-pointer" />
															</div>
														</div>
													</div>
													<div class="vx-col w-full border-bottom-green cursor-pointer"
														@click.stop="menuOption = 4">
														<div class="flex justify-between mb-3 p-0 mt-4">
															<div class="mr-4">
																<h5>Comprobante de domicilio</h5>
																<p class="mb-2">No mayor a 3 meses..</p>
															</div>
															<div class="flex align-self-center">
																<vs-chip class="mt-1" size="small"
																	:color="documentData(addressRecord).color">{{
																		documentData(addressRecord).status }}</vs-chip>
																<feather-icon icon="ChevronRightIcon" svgClasses="w-5 h-5 m-2 ml-4"
																	class="cursor-pointer" />
															</div>
														</div>
													</div>
												</div>
												<div class="vx-row">
													<div class="vx-col sm:w-1/2 md:w-1/12">
														<vs-button size="sm" color="black" type="border"
															@click.stop="mainMenuOption = 0">Atrás</vs-button>
													</div>
												</div>
											</div>
											<div v-if="menuOption == 1">
												<InvestorBusinessConstitutiveDocument v-if="isMounted"
													:onboardingStepData="onboardingStepData" :document="constitutiveAct"
													@on-back="onBack" @on-continue="onContinue" />
											</div>
											<div v-if="menuOption == 2">
												<InvestorBusinessShareholderMeetingDocument v-if="isMounted"
													:onboardingStepData="onboardingStepData" :document="shareholderMeeting"
													@on-back="onBack" @on-continue="onContinue" />
											</div>
											<div v-if="menuOption == 3">
												<InvestorBusinessFiscalRecordDocument v-if="isMounted"
													:onboardingStepData="onboardingStepData" :document="fiscalRecord"
													@on-back="onBack" @on-continue="onContinue" />
											</div>
											<div v-if="menuOption == 4">
												<InvestorBusinessAddressDocument v-if="isMounted"
													:onboardingStepData="onboardingStepData" :document="addressRecord"
													@on-back="onBack" @on-continue="onContinue" />
											</div>
										</div>
									</div>
									<!-- END MAIN-DIV -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import docsHelper from '@components/mixins/docsHelper';
import formHelper from '@components/mixins/formHelper';
import InvestorBusinessConstitutiveDocument from '@views/investor/documents/InvestorBusinessConstitutiveDocument.vue';
import InvestorBusinessShareholderMeetingDocument from '@views/investor/documents/InvestorBusinessShareholderMeetingDocument.vue';
import InvestorBusinessFiscalRecordDocument from '@views/investor/documents/InvestorBusinessFiscalRecordDocument.vue';
import InvestorBusinessAddressDocument from '@views/investor/documents/InvestorBusinessAddressDocument.vue';
import MatiPopup from "@components/mati/MatiPopup";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import ComplianceRunning from "@components/compliance/ComplianceRunning";
import FundReady from "@components/compliance/FundReady";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
	mixins: [inputHelper, docsHelper, formHelper, userIdentityHelper, investorInfoHelper],
	props: ["onboardingStepData"],
	components: {
		MatiPopup,
		ComplianceRunning,
		FundReady,
		InvestorBusinessConstitutiveDocument,
		InvestorBusinessShareholderMeetingDocument,
		InvestorBusinessFiscalRecordDocument,
		InvestorBusinessAddressDocument
	},
	data() {
		return {
			mainMenuOption: 0,
			menuOption: 0,
			isMounted: false,

			requiredRules: "required",
			errorMssg: null,
			colClass: "vx-col md:w-1/5 sm:w-full w-full mb-5",
			colClassFull: "vx-col w-full",
			requiredPlaceholder: "(Requerido)",
			allSections: [],
			allDocumentFields: [],
			validatedDocuments: [],

			fiscalRecord: null,
			constitutiveAct: null,
			addressRecord: null,
			shareholderMeeting: null,

			identity: null,
			status: null,
			processWasStarted: false,

			identityIsCompleted: false,
			investorHasOperations: false,
			isMoral: null,
			mainComplianceStatus: null,
		};
	},
	async beforeMount() {
		this.isMounted = false;
		this.showLoading(true);
		await this.getDocuments();
		await this.onUserInfoUpdated();
		await this.getIdentityInfo();
		await this.verifyCompliances();
		this.showLoading(false);
		this.isMounted = true;
	},
	watch: {
		mainMenuOption: function () {
			this.menuOption = 0;
		},
	},
	computed: {
		currentOnboardingStep() {
			return this.onboardingStepData.onboarding_step;
		},
		apiStorageBasePrefix() {
			return this.ApiDomain + "/storagev3/documents/";
		},
		businessDataIsCompleted() {
			return (this.constitutiveAct && this.fiscalRecord && this.addressRecord) ? true : false;
		},
		identityVerificationStatus() {
			return this.onboardingStepData.identity_verification_status;
		},
		mProcessIsPending() {
			return this.matiIsPending(this.status);
		},
		mshouldRunMatiProcess() {
			return this.shouldRunMatiProcess(this.status);
		},
		mverificationIsCompleted() {
			return this.verificationIsCompleted(this.status);
		},
		mverificationIsRejected() {
			return this.verificationIsRejected(this.status);
		},
		mverificationIsInProcess() {
			return this.verificationIsInProcess(this.status);
		},
		mverificationisNewRequired() {
			return this.verificationisNewRequired(this.status);
		},
		mverificationIsReviewNeeded() {
			return this.verificationIsReviewNeeded(this.status);
		},
		mverficationNoSuccessful() {
			return this.mverificationIsRejected || this.mverificationisNewRequired || this.mverificationIsReviewNeeded;
		}
	},
	methods: {
		async onBack() {
			this.menuOption = 0;
		},
		async onContinue() {
			this.showLoading(true, "Guardando información...");
			await this.getDocuments();
			this.menuOption = 0;
			this.showLoading(false);
		},
		async saveDataForm() {
			this.errorMssg = null;
			if (!(await this.validateForm())) {
				return;
			}
			this.showLoading(true, "Guardando información...");
			try {
				let payload = {
					// onboarding_step: this.currentOnboardingStep,
				};
				await axios.post(
					"/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
					payload
				);
				this.showLoading(false);
				this.$emit("updated", 1);
			} catch (e) {
				this.showLoading(false);
				this.showError(e);
			}
		},
		async validateForm() {
			this.errorMssg = null;
			let complete = false;
			if (this.$validator.validateAll()) {
				complete = true;
			} else {
				this.errorMssg = "Debes completar todos los campos";
				setTimeout(() => this.errorMssg = null, 5000);
			}
			return complete;
		},
		businessData() {
			let data = {
				status: "Pendiente",
				color: "warning"
			};
			if (this.businessDataIsCompleted) {
				data.status = "Completado";
				data.color = "success";
			}
			return data;
		},
		getIdentityData() {
			let data = {
				status: "Pendiente",
				color: "warning"
			};
			if (this.identityVerificationStatus) {
				switch (this.identityVerificationStatus) {
					case 0:
						data.status = "Cargado";
						data.color = "success";
						break;
					case 1:
						data.status = "Completado";
						data.color = "success";
						break;
					case 2:
						data.status = "En revisión";
						data.color = "success";
						break;
					case 4:
						data.status = "Rechazada";
						data.color = "warning";
						break;
					default:
						data.status = "En revisión";
						data.color = "success";
						break;
				}
			}
			return data;
		},
		documentData(document) {
			let data = {
				status: "Pendiente",
				color: "warning"
			};
			if (document) {
				if (document.is_verified == "verificado") {
					data.status = "Completado";
					data.color = "success";
				} else if (document.is_verified == "rechazado") {
					data.status = "Rechazado";
					data.color = "warning";
				} else if (document.is_verified == "sin verificar") {
					data.status = "Cargado";
					data.color = "success";
				}
			}
			return data;
		},
		async getDocuments() {
			this.fiscalRecord = await this.getDocument(105);
			this.constitutiveAct = await this.getDocument(106);
			this.addressRecord = await this.getDocument(108);
			this.shareholderMeeting = await this.getDocument(182);
		},
		async getDocument(docId) {
			let document = null;
			try {
				const response = await axios.get(`${this.apiStorageBasePrefix}get/document-data/${docId}/${this.UserId}`);
				document = response.data;
			} catch (error) {
				console.log(error);
				this.showLoading(false);
				this.$vs.notify({
					title: 'Error',
					text: 'Ocurrió un error al obtener la información',
					color: 'danger',
					position: 'top-right'
				});
			}
			return document;
		},
		async verifyCompliances() {
			// si el inversionista ya ha hecho operaciones, tambien se le muestra el banner
			if (this.InvestorStatus > 2) {
				this.investorHasOperations = true;
				//return;
			}

			// se verifica la identidad en general (ya sea p.fisica o p.moral)
			this.isMoral = this.userIsMoral(this.UserPersonType);
			let isForeign = this.userIsForeign(this.UserPersonType);

			// definir si mati esta pendiente o debe hacerse de nueva cuenta
			const shouldRunMati = this.shouldRunMatiProcess(this.status);
			if (shouldRunMati) {
				this.mainComplianceStatus = "pending";
				return;
			}

			const matiIsRunning = this.matiIsRunning(this.status);
			if (matiIsRunning) {
				this.mainComplianceStatus = "running";
				return;
			}

			// definir si esta completado
			const matiCompleted = this.verificationIsCompleted(this.status);
			if (this.isMoral || isForeign) {
				if (matiCompleted) {
					this.mainComplianceStatus = "completed";
				}
				else {
					this.mainComplianceStatus = "running";
				}
			}
			else {
				const stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
				if (matiCompleted && stpClabe !== null) {
					this.mainComplianceStatus = "completed";
				}
				else {
					this.mainComplianceStatus = "running";
				}
			}
		},
		async onMatiProcessStarted() {
			this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
			this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", false);
			this.processWasStarted = true;
		},
		async openMatiPopup() {
			this.$store.commit("SET_MATI_PROCESS_IS_RUNNING", true);
			this.$store.commit("TOGGLE_IS_MATI_POPUP_ACTIVE", true);
		},
		async getIdentityInfo() {
			this.status = await this.getUserIdentityStatus(this.UserId);
			this.identityIsCompleted = this.verificationIsCompleted(this.status);
		},
		showError(e) {
			console.log(e);
			this.warn(e);
			let err = "Ha ocurrido un error con la operación, intente más tarde";
			if (e.response && e.response.data && e.response.data.error) {
				err = e.response.data.error;
			}
			this.errorMssg = err;
		},
	},
};
</script>
