<template>
  <div id="6_rfc_curp_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Valida tu RFC y CURP
                        </p>
                        <p>
                          Si tus datos no coinciden, ingrésalos correctamente.
                        </p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (rfc = rfc.toUpperCase())"
                            class="w-full"
                            label="RFC *"
                            name="rfc"
                            v-validate="requiredRules"
                            v-model.lazy="rfc"
                            :danger="hasError('rfc')"
                            :danger-text="errorText('rfc')"
                            :success="isSuccess('rfc')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                          <a
                            class="inline-link-primary"
                            target="_blank"
                            rel="noopener"
                            :href="$sanitizeUrl(rfcUrl)"
                            >Consultar mi RFC</a
                          >
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (curp = curp.toUpperCase())"
                            class="w-full"
                            label="CURP *"
                            name="curp"
                            v-validate="requiredRules"
                            v-model.lazy="curp"
                            :danger="hasError('curp')"
                            :danger-text="errorText('curp')"
                            :success="isSuccess('curp')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                          <a
                            class="inline-link-primary"
                            target="_blank"
                            rel="noopener"
                            :href="$sanitizeUrl(curpUrl)"
                            >Consultar mi CURP</a
                          >
                        </div>
                      </div>
                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="6_rfc_curp_confirm_btn" class="w-full mt-4" @click="saveDataForm"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
export default {
  mixins: [inputHelper, formatHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      rfcUrl: "https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp",
      curpUrl: "https://www.gob.mx/curp/",
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col lg:w-1/2 sm:w-full w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: "(Opcional)",
      rfc: null,
      curp: null,
    };
  },
  async beforeMount() {
    this.rfc = this.onboardingStepData.rfc;
    this.curp = this.onboardingStepData.curp;
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
  },
  methods: {
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      let validRfc = this.isValidRfcFormat(this.rfc);
      if(!validRfc){
        this.errorMssg = "La estructura de la clave RFC es incorrecta."
        return;
      }
      let validCurp = this.isValidCurpFormat(this.curp);
      if(!validCurp){
        this.errorMssg = "La estructura de la clave CURP es incorrecta."
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          rfc: this.rfc,
          curp: this.curp,
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
