<template>
	<div id="business-address-confirmation" class="mt-base">
		<div class="vx-row mb-4">
			<div class="vx-col w-full mb-4">
				<h2>Comprobante de domicilio</h2>
				<p>Ingresa el comprobante de domicilio no mayor a 3 meses, legible, sin cortes.</p>
				<vs-divider></vs-divider>
			</div>
			<div class="vx-col sm:w-full md:w-10/12 mb-4">
				<h4 class="mb-base">Selección del documento</h4>
				<div class="vx-row">
					<div class="vx-col sm:w-full md:w-1/2">
						<div class="document-detail">
							<p class="bold black text-lg mb-4">Antes de subir el documento verifica que el comprobante:</p>
							<ul class="check-ul">
								<li class="black">Tenga <span class="bold">máximo 3 meses</span> de antigüedad.</li>
								<li class="black">Muestre la <span class="bold">calle, colonia y código postal</span>. Debe ser
									legible.</li>
								<li class="black">Esté completo, sin cortes.</li>
								<li class="black">Cumpla con formato PDF, JPG, GIF o PNG.</li>
								<li class="black">El archivo pese máximo 10 MB.</li>
							</ul>
						</div>
					</div>
					<div class="vx-col sm:w-full md:w-1/2">
						<p class="bold black text-lg mb-4">Puede ser de cualquiera de estos servicios</p>
						<div class="vx-row flex-end mb-base">
							<div class="vx-col sm:w-1/2 md:w-2/12 center">
								<img :src="icons['BulbIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4"
									class="cursor-pointer text-accent align-self-felx-start" />
								<p class="mt-2">Luz</p>
							</div>
							<div class="vx-col sm:w-1/2 md:w-2/12 center phone">
								<img :src="icons['PhoneIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4"
									class="cursor-pointer text-accent align-self-felx-start" />
								<p class="mt-2">Teléfono</p>
							</div>
							<div class="vx-col sm:w-1/2 md:w-2/12 center">
								<img :src="icons['DropIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4"
									class="cursor-pointer text-accent align-self-felx-start" />
								<p class="mt-2">Agua</p>
							</div>
							<div class="vx-col sm:w-1/2 md:w-2/12 center">
								<img :src="icons['MonitorIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4"
									class="cursor-pointer text-accent align-self-felx-start" />
								<p class="mt-2">Cable</p>
							</div>
							<div class="vx-col sm:w-1/2 md:w-2/12 center">
								<img :src="icons['FlameIcon']" svgClasses="w-10 h-10 m-1 mr-2 mt-0 mb-4"
									class="cursor-pointer text-accent align-self-felx-start" />
								<p class="mt-2">Gas</p>
							</div>
						</div>
						<div class="vx-row flex-end mb-base">
							<div class="vx-col sm:w-full">
								<div class="centerx">
									<vx-upload class="upload-container" text="Seleccione o arrastre un archivo" ref="vsupload"
										:automatic="true" :action="uploadAction" :data="{
											file_type: 'investor_pm_address'
										}" :alreadyLoaded="hasAlreadyDocument" :fileIsVerified="isVerified"
										:fileIsRejected="isRejected" :loadedFileName="documentName" fileName="file"
										:acceptFileTypes="['pdf']" :headers="headers" @on-success="successUpload"
										@on-invalid-extension="onInvalidExtensionEvent" />
									<div v-if="showAlerts" class="flex justify-between flex-wrap mb-4 small-flex">
										<vs-alert v-if="onError && !fileWasLoaded" color="danger" style="height: auto">{{
											invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ?
											apiErrors : '' }}</vs-alert>
										<vs-alert v-if="onError && fileWasLoaded" color="danger" style="height: auto">{{
											invalidExtension ? invalidExtensionError : genericError }} {{ apiErrors != '' ?
											apiErrors : '' }} {{ remainMssg }}</vs-alert>
										<vs-alert v-if="onSuccess" color="success" style="height: auto">
											El archivo ha sido cargado correctamente{{ (docSets.verification_required) ? " y será validado por el equipo de RedGirasol." : "."}}
										</vs-alert>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="vx-row">
			<div class="vx-col sm:w-1/2 md:w-1/12">
				<vs-button size="sm" color="black" type="border" @click.stop="goBack()">Atrás</vs-button>
			</div>
			<div class="vx-col sm:w-1/2 md:w-2/12">
				<!-- <vs-button class="w-full" size="sm" color="black" @click.stop="goContinue()">Continuar</vs-button> -->
			</div>
		</div>
	</div>
</template>

<script>
import VxUpload from '@upload/VxUpload';
export default {
	name: "InvestorBusinessAddressDocument",
	props: ["onboardingStepData", "document"],
	components: {
		VxUpload
	},
	data: () => ({
		icons: {
			'BulbIcon': require("@assets/images/loan-onboarding/bulb-icon.svg"),
			'PhoneIcon': require("@assets/images/loan-onboarding/phone-icon.svg"),
			'DropIcon': require("@assets/images/loan-onboarding/drop-icon.svg"),
			'MonitorIcon': require("@assets/images/loan-onboarding/monitor-icon.svg"),
			'FlameIcon': require("@assets/images/loan-onboarding/flame-icon.svg")
		},
		onError: false,
		onSuccess: false,
		hasFile: false,
		/* mensajes */
		genericError: "Ha ocurrido un error con la carga.",
		invalidExtensionError: "El tipo de archivo seleccionado no es válido, intente con otro archivo.",
		updateError: "Ha ocurrido un error al intentar actualizar",
		remainMssg: "El archivo cargado previamente seguirá activo.",
		apiErrors: ""
	}),
	computed: {
		base() {
			return this.onboardingStepData;
		},
		apiStorageBasePrefix() {
			return this.ApiDomain + "/storagev3/documents/";
		},
		currentAccessToken() {
			return this.AccessToken;
		},
		uploadAction() {
			let url = this.apiStorageBasePrefix;
			if (this.hasAlreadyDocument) {
				return `${url}upload/user/${this.UserId}/update/${this.document.id}`;
			} else {
				return `${url}upload/user/${this.UserId}`;
			}
			// if (this.fileWasLoaded) {
			//     // actualizacion
			//     if (this.isUserDoc)
			// } else {
			// }
		},
		headers() {
			// "Access-Control-Allow-Origin": process.env.VUE_APP_API_DOMAIN,
			//     "Access-Control-Allow-Credentials": process.env.VUE_APP_CORS_ALLOW_CREDENTIALS,
			return {
				"Access-Control-Allow-Origin": false,
				"Access-Control-Allow-Credentials": false,
				"X-Requested-With": "XMLHttpRequest",
				"Authorization": "Bearer " + this.currentAccessToken,
			};
		},
		showAlerts() {
			return (this.onSuccess || this.onError);
		},
		hasAlreadyDocument() {
			return this.document != null && this.document.id != null;
		},
		isVerified() {
			return (this.document.is_verified == "verificado");
		},
		isRejected() {
			return (this.document.is_verified == "rechazado");
		},
		documentName() {
			return this.document?.storage_file;
		}
	},
	methods: {
		async goBack() {
			await this.$emit('on-back', 1);
		},
		async goContinue() {
			await this.$emit('on-continue', 1);
		},
		async upload() {
			let doc = document.getElementById("vsupload");
			const formData = new FormData();
			const data = {
				file_type: "investor_pm_address"
			};
			this.injectAccountMetadataToFormData(obj);
			for (var key in data) {
				formData.append(key, data[key]);
			}

			if (doc.files.length > 0) {
				data.append("applicants_layout", doc.files[0]);
				const res = await axios.post(`${this.uploadAction()}`, data);
			}
		},
		successUpload() {
			this.$vs.notify({
				title: '¡Documento subido!',
				text: 'Tu documento ha sido subido con éxito.',
				color: 'success',
				position: 'top-right'
			});
			this.$emit('on-continue', 1);
		},
		onInvalidExtensionEvent() {
			this.resetFlags();
			this.onError = true;
			this.invalidExtension = true;
		},
		// onSuccessEvent(res){
		//     let data = JSON.parse(res);
		//     this.resetFlags();
		//     this.apiErrors =  "",
		//     this.onSuccess = true;
		//     // this.fileWasLoaded = true;
		//     // this.visibleStateLoaded = true;
		//     // this.empty = false;

		//     this.document.verified = (data.is_verified == verifiedEnumValue);
		//     this.document.rejected = (data.is_verified == rejectedEnumValue);
		//     this.document.db_id = data.id;
		//     this.document.updated_at = data.updated_at;

		//     this.$emit("on-success", data);
		// },
	}
}
</script>

<style>
.address-card {
	border-radius: 8px;
	border: 1px solid #28DE18;
	padding: 1rem;
	width: fit-content;
}

.vs-button.small:not(.includeIconOnly) {
	padding: 1px
}

.address-card {
	padding: 2rem;
	width: fit-content;

}

.center {
	text-align: -webkit-center;
	margin-right: 10px;

}

.center.phone p {
	margin-left: -1rem;
}

.flex-end {
	place-items: flex-end;
}

ul.check-ul {
	list-style: none;
	line-height: 35px;
}

ul.check-ul li:before {
	content: '✓';
	color: #28DE18;
	margin-right: 12px;
}

.con-input-upload {
	background-color: #f7fff6;
	width: inherit;
	margin: 0;
	margin-left: 0;
	/* width: 35rem !important; */
	font-size: 1.3rem !important;
}

.doc-list {
	list-style: disc;
	margin-left: 1.3rem;
}
</style>