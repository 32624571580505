<template>
	<div id="pm_mex_8_kyc_profile_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
		<div class="vx-row no-gutter items-center justify-center p-6">
			<vx-card class="login-card py-3 px-3">
				<div slot="no-body" class="">
					<div class="vx-col sm:w-full">
						<div class="login-tabs-container">
							<div class="vx-row m-0">
								<div class="vx-col w-full p-0" style="min-height: 200px">
									<!-- MAIN-DIV -->
									<div class="px-6 pt-5 pb-5">
										<!-- HEADER -->
										<div class="vx-row mb-4">
											<div class="vx-col w-full">
												<p class="text-xl card-sub-title">Cuéntanos un poco sobre tus planes de inversión
												</p>
												<p>Estos datos serán un aproximado de cómo piensas operar en la Red.</p>
											</div>
										</div>
										<vs-divider color="dark"></vs-divider>
										<!-- CONTENT -->
										<div class="main-form mt-base">
											<label class="vs-input--label bold">¿Cuántas veces al mes piensas agregar fondos?
												*</label>
											<v-error v-if="errorMssg && !numberMonthlyTransactionsValue"
												error="Este campo es obligatorio" />
											<div class="vx-row mt-2">
												<div class="vx-col md:w-1/3 sm:w-full w-full mb-5"
													v-for="item in collections.numberMonthlyTransactions" :key="item.label">
													<rg-radio class="w-full" groupName="numberMonthlyTransactions"
														:label="item.label" :value="item.value"
														v-model="numberMonthlyTransactionsValue" />
												</div>
											</div>
											<label class="vs-input--label bold">Monto de cada depósito *</label>
											<v-error v-if="errorMssg && !initialTrxProfileAverageDepositValue"
												error="Este campo es obligatorio" />
											<div class="vx-row mt-2">
												<div :class="colClass" v-for="item in collections.initialTrxProfileAverageDeposits"
													:key="item.label">
													<rg-radio class="w-full" groupName="initialTrxProfileAverageDeposits"
														:label="item.label" :value="item.value"
														v-model="initialTrxProfileAverageDepositValue" />
												</div>
											</div>
											<label class="vs-input--label bold">¿Cuál es el origen de estos recursos? *</label>
											<v-error v-if="errorMssg && !sofTypeValue" error="Este campo es obligatorio" />
											<div class="vx-row mt-2">
												<div :class="colClass" v-for="item in collections.sofTypesPm" :key="item.label">
													<rg-radio class="w-full" groupName="sofTypesPm" :label="item.label"
														:value="item.value" v-model="sofTypeValue" />
												</div>
											</div>
											<label class="vs-input--label bold">¿Qué destino le darás a tu inversión? *</label>
											<v-error v-if="errorMssg && !dofTypeValue" error="Este campo es obligatorio" />
											<div class="vx-row mt-2">
												<div :class="colClass" v-for="item in collections.dofTypes" :key="item.label">
													<rg-radio class="w-full" groupName="dofTypes" :label="item.label"
														:value="item.value" v-model="dofTypeValue" />
												</div>
											</div>
											<label class="vs-input--label bold">¿Qué te motiva a invertir con nosotros? *</label>
											<div class="vx-row mt-2">
												<div :class="colClassFull">
													<vx-textarea class="w-full" v-model.lazy="businessPurpose" name="businessPurpose"
														v-validate="requiredRules" :danger="hasError('businessPurpose')"
														:danger-text="errorText('businessPurpose')"
														:success="isSuccess('businessPurpose')" :placeholder="requiredPlaceholder"
														height="100px" maxlength="255" />
												</div>
											</div>
											<vs-alert v-if="errorMssg" icon-pack="feather" icon="icon-alert-triangle" class="mb-5"
												color="danger">
												<span class="font-regular">{{ errorMssg }}</span>
											</vs-alert>
											<div class="vx-row">
												<div class="vx-col md:w-1/2 sm:w-full w-full"></div>
												<div class="vx-col md:w-1/2 sm:w-full w-full">
													<vs-button id="pm_mex_8_kyc_profile_confirm_btn" class="w-full"
														@click="saveDataForm">Continuar</vs-button>
												</div>
											</div>
										</div>
										<!-- END CONTENT DIV -->
									</div>
									<!-- END MAIN-DIV -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formHelper from '@components/mixins/formHelper';
export default {
	mixins: [inputHelper, formHelper],
	props: ["onboardingStepData"],
	data() {
		return {
			collections: {
				sofTypesPm: [],
				numberMonthlyTransactions: [],
				initialTrxProfileAverageDeposits: [],
				dofTypes: []
			},
			requiredRules: "required",
			errorMssg: null,
			colClass: "vx-col md:w-1/5 sm:w-full w-full mb-5",
			colClassFull: "vx-col w-full",
			requiredPlaceholder: "(Requerido)",
			numberMonthlyTransactionsValue: null,
			initialTrxProfileAverageDepositValue: null,
			sofTypeValue: null,
			dofTypeValue: null,
			businessPurpose: null,
			allSections: []
		};
	},
	async beforeMount() {
		this.showLoading(true);
		await this.getCollections();
		this.numberMonthlyTransactionsValue = this.onboardingStepData.number_monthly_transactions;
		this.initialTrxProfileAverageDepositValue = this.onboardingStepData.initial_trx_profile_average_deposit;
		this.sofTypeValue = this.onboardingStepData.sof_type;
		this.dofTypeValue = this.onboardingStepData.dof_type;
		this.businessPurpose = this.onboardingStepData.business_purpose;
		this.showLoading(false);
	},
	computed: {
		currentOnboardingStep() {
			return this.onboardingStepData.onboarding_step;
		},
	},
	methods: {
		async getCollections() {
			try {
				let collectionsObjects = ['sofTypes', 'monthlyTransactionAverage', 'averageDeposits', 'getFundsDestinations'];
				let params = "with[]=" + collectionsObjects.join("&with[]=");
				let res = await this.publicApiGet(`/api/v1/forms/getPublicFormCollections?${params}`);
				let colls = res.data;
				colls.sofTypes.forEach(opt => {
					if (opt.kind == "PM") {
						this.collections.sofTypesPm.push({ value: opt.id, label: opt.name }); //conversion a sintaxis de vx-select
					}
				})
				colls.monthlyTransactionAverage.forEach(opt => {
					this.collections.numberMonthlyTransactions.push({ value: opt.id, label: opt.name }); //conversion a sintaxis de vx-select
				})
				colls.averageDeposits.forEach(opt => {
					this.collections.initialTrxProfileAverageDeposits.push({ value: opt.id, label: opt.name }); //conversion a sintaxis de vx-select
				})
				colls.getFundsDestinations.forEach(opt => {
					if (opt.kind == "PM" || opt.kind == "PF_PM") {
						this.collections.dofTypes.push({ value: opt.id, label: opt.name }); //conversion a sintaxis de vx-select
					}
				})
			}
			catch (e) {
			}
		},
		async saveDataForm() {
			this.errorMssg = null;
			if (!(await this.validateForm())) {
				return;
			}
			this.showLoading(true, "Guardando información...");
			try {
				let payload = {
					// onboarding_step: this.currentOnboardingStep,
					number_monthly_transactions: this.numberMonthlyTransactionsValue,
					initial_trx_profile_average_deposit: this.initialTrxProfileAverageDepositValue,
					sof_type: this.sofTypeValue,
					dof_type: this.dofTypeValue,
					business_purpose: this.businessPurpose
				};
				await axios.post(
					"/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
					payload
				);
				this.showLoading(false);
				this.$emit("updated", 1);
			} catch (e) {
				this.showLoading(false);
				this.showError(e);
			}
		},
		async validateForm() {
			this.errorMssg = null;
			let complete = false;
			if (this.$validator.validateAll()) {
				if (this.numberMonthlyTransactionsValue != null && this.initialTrxProfileAverageDepositValue != null && this.sofTypeValue != null && this.dofTypeValue != null && this.businessPurpose != null && this.businessPurpose != "") {
					complete = true;
				} else {
					this.errorMssg = "Debes completar todos los campos";
					setTimeout(() => this.errorMssg = null, 5000);
				}
			}
			return complete;
		},
		showError(e) {
			console.log(e);
			this.warn(e);
			let err = "Ha ocurrido un error con la operación, intente más tarde";
			if (e.response && e.response.data && e.response.data.error) {
				err = e.response.data.error;
			}
			this.errorMssg = err;
		},
		hasError(val) {
			return this.errors.has(val);
		},
		errorText(val) {
			return this.errors.first(val);
		},
		isSuccess(val) {
			let ff = this.fields[val];
			return ff && ff.required && ff.valid;
		},
	},
};
</script>
