<template>
  <div id="3_name_container" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">¿Cuál es tu nombre?</p>
                        <p>
                          Deberás ingresarlo tal cual aparece en tu identificación
                          oficial (INE, pasaporte, residencia permanente o
                          temporal)
                        </p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <div class="vx-row">
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (firstName = firstName.toUpperCase())"
                            class="w-full"
                            label="Primer nombre *"
                            name="firstName"
                            v-validate="requiredRules"
                            v-model.lazy="firstName"
                            :danger="hasError('firstName')"
                            :danger-text="errorText('firstName')"
                            :success="isSuccess('firstName')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (secondName = secondName.toUpperCase())"
                            class="w-full"
                            label="Segundo nombre"
                            name="secondName"
                            v-model.lazy="secondName"
                            :danger="hasError('secondName')"
                            :danger-text="errorText('secondName')"
                            :success="isSuccess('secondName')"
                            icon-pack="feather"
                            :placeholder="optionalPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (lastName1 = lastName1.toUpperCase())"
                            class="w-full"
                            label="Primer apellido *"
                            name="lastName1"
                            v-validate="requiredRules"
                            v-model.lazy="lastName1"
                            :danger="hasError('lastName1')"
                            :danger-text="errorText('lastName1')"
                            :success="isSuccess('lastName1')"
                            icon-pack="feather"
                            :placeholder="requiredPlaceholder"
                          />
                        </div>
                        <div :class="colClass">
                          <vs-input
                            @input="(val) => (lastName2 = lastName2.toUpperCase())"
                            class="w-full"
                            label="Segundo apellido"
                            name="lastName2"
                            v-model.lazy="lastName2"
                            :danger="hasError('lastName2')"
                            :danger-text="errorText('lastName2')"
                            :success="isSuccess('lastName2')"
                            icon-pack="feather"
                            :placeholder="optionalPlaceholder"
                          />
                        </div>
                      </div>
                      <vs-alert
                        v-if="errorMssg"
                        icon-pack="feather"
                        icon="icon-alert-triangle"
                        class="mb-5"
                        color="danger"
                      >
                        <span class="font-regular">{{ errorMssg }}</span>
                      </vs-alert>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="3_name_confirm_btn" class="w-full mt-4" @click="saveDataForm"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  mixins: [inputHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: "(Opcional)",
      firstName: null,
      secondName: null,
      lastName1: null,
      lastName2: null,
    };
  },
  beforeMount(){
    this.firstName = this.onboardingStepData.first_name;
    this.secondName = this.onboardingStepData.second_name;
    this.lastName1 = this.onboardingStepData.last_name_1;
    this.lastName2 = this.onboardingStepData.last_name_2;
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
  },
  methods: {
    async saveDataForm() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          first_name: this.firstName,
          second_name: this.secondName,
          last_name_1: this.lastName1,
          last_name_2: this.lastName2,
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
