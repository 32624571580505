<template>
  <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
    <div class="vx-row no-gutter items-center justify-center px-6">
      <vx-card>
          <div v-if="!currentReferalCode">
            <div v-if="isMainOnboardingStepVisible" class="vx-col sm:w-full">
                <p class="text-center bold">
                ¿Tienes un código de re(d)feridos? Ingrésalo 
                <a
                    class="inline-link"
                    target="_blank"
                    rel="noopener"
                    @click="showForm"
                    >aquí</a>.
                </p>
            </div>
            <div v-if="isFormVisible" class="vx-col sm:w-full">
                <p class="text-center bold">
                  Ingresa tu código
                </p>
                <div class="main-form">
                  <div class="vx-row">
                    <div :class="colClass">
                      <vs-input
                        class="w-full"
                        label="Ingresa el código. *"
                        name="referalCode"
                        v-validate="requiredRules"
                        v-model.lazy="referalCode"
                        :danger="hasError('referalCode')"
                        :danger-text="errorText('referalCode')"
                        :success="isSuccess('referalCode')"
                        icon-pack="feather"
                        :placeholder="requiredPlaceholder"
                      />
                    </div>
                    <div :class="colClass">
                      <div class="flex">
                        <vs-button
                          class="w-full mt-6 mr-2"
                          @click="saveReferalCode"
                          >Agregar</vs-button
                        >
                        <vs-button
                          color="dark"
                          class="w-full mt-6"
                          @click="showMainOnboardingStep"
                          >Cancelar</vs-button
                        >
                      </div>
                    </div>
                  </div>
                  <vs-alert
                    v-if="errorMssg"
                    icon-pack="feather"
                    icon="icon-alert-triangle"
                    color="danger"
                  >
                    <span class="font-regular">{{ errorMssg }}</span>
                  </vs-alert>
                  <vs-alert
                    v-if="successMssg"
                    icon-pack="feather"
                    icon="icon-check"
                    color="success"
                  >
                    <span class="font-regular">{{ successMssg }}</span>
                  </vs-alert>
                </div>
            </div>
          </div>
          <!-- REFERAL CODE MESSAGE -->
          <div v-else>
            <vs-alert
              icon-pack="feather"
              icon="icon-info"
              style="height:auto"
              color="rgb(41, 147, 138)">
              Estás utilizando el código de invitación <span class="bold">{{currentReferalCode}}</span>.
            </vs-alert>
          </div>
          <!-- END REFERAL CODE MESSAGE -->
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  mixins: [inputHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      successMssg: null,
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      optionalPlaceholder: '(Opcional)',
      isMainOnboardingStepVisible: true,
      isFormVisible: false,
      referalCode: null,
    };
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    currentReferalCode() {
      return this.onboardingStepData.referal_code;
    },
  },
  methods: {
    showMainOnboardingStep() {
      this.isMainOnboardingStepVisible = true;
      this.isFormVisible = false;
    },
    showForm() {
      this.isMainOnboardingStepVisible = false;
      this.isFormVisible = true;
    },

    async saveReferalCode() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Registrando el código...");
      try {
        let payload = {
          investor_id: this.InvestorId,
          referal_code: this.referalCode,
          from_referal: true
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/updateReferalCode",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
  },
};
</script>
