<template>
    <div :id="idContainer" class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
      <div class="vx-row no-gutter items-center justify-center p-6">
        <vx-card class="login-card py-3 px-3">
          <div slot="no-body" class="">
            <div class="vx-col sm:w-full">
              <div class="login-tabs-container">
                <div class="vx-row m-0">
                  <div class="vx-col w-full p-0" style="min-height: 200px">
                    <!-- MAIN-DIV -->
                    <div v-if="isMainOnboardingStepVisible" class="px-6 pt-5 pb-5">
                      <!-- HEADER -->
                      <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                          <p class="text-2xl card-sub-title">Verifica tu cuenta</p>
                          <p>
                            <template v-if="currentPhoneNumber != null">
                              Te hemos enviado un código por mensaje de texto al
                              siguiente número:
                              <strong>{{ visibleLastNChars(currentPhoneNumber, 4) }}. </strong>
                            </template>
                            <a
                              class="inline-link-primary"
                              target="_blank"
                              rel="noopener"
                              @click="showPhoneForm"
                              >Cambiar número</a
                            >
                          </p>
                        </div>
                      </div>
                      <vs-divider color="dark"></vs-divider>
                      <!-- CONTENT -->
                      <div class="main-form mt-base">
                        <div class="vx-row">
                          <div :class="colClass">
                            <vs-input
                              class="w-full"
                              label="Ingresa el código que recibiste. *"
                              name="verification_code"
                              v-validate="requiredRules"
                              v-model.lazy="
                                onboardingStepDataForm.verification_code
                              "
                              :danger="hasError('verification_code')"
                              :danger-text="errorText('verification_code')"
                              :success="isSuccess('verification_code')"
                              icon-pack="feather"
                              :placeholder="requiredPlaceholder"
                            />
                            <small>
                              {{ whatsappTime == 0 ? `El código ha expirado, puedes solicitar uno nuevo.` : `El código estará activo durante ${ whatsappTime } segundos` }}
                            </small>
                          </div>
                        </div>
                        <vs-alert
                          v-if="errorMssg"
                          icon-pack="feather"
                          icon="icon-alert-triangle"
                          class="mb-5"
                          color="danger"
                        >
                          <span class="font-regular">{{ errorMssg }}</span>
                        </vs-alert>
                        <vs-alert
                          v-if="successMssg"
                          icon-pack="feather"
                          icon="icon-check"
                          class="mb-5"
                          color="success"
                        >
                          <span class="font-regular">{{ successMssg }}</span>
                        </vs-alert>
                        <div class="vx-row">
                          <div class="vx-col w-full">
                            <verification-code-options
                              class="mb-4"
                              :isDisabled="!showWhatsapp"
                              :phone-text="visibleLastNChars(currentPhoneNumber, 4)"
                              @on-whatsapp-clicked="resendVerificationCodeWhatsapp"
                              @on-sms-clicked="resendVerificationCode"
                            />
                            <div class="mt-12">
                              <vs-button
                                :id="idConfirmBtn"
                                class="w-full"
                                @click="doVerificationCode"
                                >Validar código</vs-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END CONTENT DIV -->
                    </div>
                    <!-- END MAIN-DIV -->

                    <!-- PHONE-FORM-DIV -->
                    <div v-if="isFormPhoneVisible" class="px-6 pt-5 pb-5">
                      <!-- HEADER -->
                      <div class="vx-row mb-4">
                        <div class="vx-col w-full">
                          <p class="text-2xl card-sub-title">
                            Ingresa tu teléfono celular.
                          </p>
                        </div>
                      </div>
                      <vs-divider color="dark"></vs-divider>
                      <!-- CONTENT -->
                      <div class="main-form mt-base">
                        <div class="vx-row">
                          <div :class="colClass">
                            <label class="vs-input--label"
                              >Teléfono celular *</label
                            >
                            <div class="flex">
                              <vue-country-code
                                name="dialCode"
                                class="vs-custom"
                                data-vv-name="dialCode"
                                :enableSearchField="true"
                                searchPlaceholderText="Buscar código de país"
                                :enabledCountryCode="true"
                                @onSelect="onSelect"
                                :preferredCountries="['MX']"
                                :danger="hasError('dialCode')"
                                :danger-text="errorText('dialCode')"
                                :success="isSuccess('dialCode')">
                              </vue-country-code>
                              <vs-input
                                class="w-full"
                                label=""
                                name="phone"
                                type="tel"
                                v-validate="'required|numeric'"
                                v-model.lazy="onboardingPersonalData.phone"
                                :danger="hasError('phone')"
                                :danger-text="errorText('phone')"
                                :success="isSuccess('phone')"
                                icon-pack="feather"
                                placeholder="(Requerido)"
                              />
                            </div>
                          </div>
                        </div>
                        <vs-alert
                          v-if="errorMssg"
                          icon-pack="feather"
                          icon="icon-alert-triangle"
                          class="mb-5"
                          color="danger"
                        >
                          <span class="font-regular">{{ errorMssg }}</span>
                        </vs-alert>
                        <div class="vx-row">
                          <div class="vx-col w-full">
                            <div class="flex">
                              <vs-button v-if="currentPhoneNumber"
                                color="dark"
                                class="w-full"
                                @click="showMainOnboardingStep"
                                >Regresar</vs-button
                              >
                              <vs-button
                                class="w-full ml-2"
                                @click="changeUserPhone"
                                >Reenviar código</vs-button
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- END CONTENT DIV -->
                    </div>
                    <!-- END PHONE-FORM-DIV -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import formatHelper from "@mixins/formatHelper";
import VerificationCodeOptions from "../../share/profile/VerificationCodeOptions.vue";
import investorInfoHelper from "@/helpers/investorInfoHelper";

export default {
  mixins: [inputHelper,formatHelper, investorInfoHelper],
  components: {
    VerificationCodeOptions
  },
  props: ["onboardingStepData"],
  data() {
    return {
      requiredRules: "required",
      errorMssg: null,
      successMssg: null,
      colClass: "vx-col w-full mb-5",
      requiredPlaceholder: "(Requerido)",
      isMainOnboardingStepVisible: true,
      isFormPhoneVisible: false,
      onboardingStepDataForm: {
        verification_code: null,
      },
      onboardingPersonalData: {
        country_calling_code: null,
        phone: null,
      },
      showWhatsapp: false,
      whatsappTime: 60,
      timer: null
      
    };
  },
  mounted(){
    if (this.currentPhoneNumber == null) {
      this.isMainOnboardingStepVisible = false;
      this.isFormPhoneVisible = true;
    }
    this.initNipCounter();
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    currentPhoneNumber() {
      return this.onboardingStepData.phone_number;
    },
    isMoral(){
      return this.userIsMoral(this.UserPersonType);
    },
    idContainer(){
      return this.isMoral? 'pm_mex_2_cellphone_checked_container':'2_cellphone_checked_container';
    },
    idConfirmBtn(){
      return this.isMoral? 'pm_mex_2_cellphone_checked_confirm_btn':'2_cellphone_checked_confirm_btn';
    }
  },
  methods: {
    showMainOnboardingStep() {
      this.isMainOnboardingStepVisible = true;
      this.isFormPhoneVisible = false;
      this.setUpTimerConfig();
      this.initNipCounter();
    },
    showPhoneForm() {
      this.errorMssg = null;
      this.onboardingPersonalData.phone = null;
      this.onboardingPersonalData.country_calling_code = null;
      this.isMainOnboardingStepVisible = false;
      this.isFormPhoneVisible = true;
    },
    async changeUserPhone() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Cambiando el teléfono...");
      try {
        let payload = {
          investor_id: this.InvestorId,
          personal: this.onboardingPersonalData,
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/updateAndVerifyPhone",
          payload
        );
        this.showMainOnboardingStep();

        this.showSuccess("Teléfono cambiado exitosamente");
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    resendVerificationCodeWhatsapp(){
      this.resendVerificationCode(true);
    },
    async resendVerificationCode(isWhatsapp = false) {
      this.errorMssg = null;
      this.showLoading(true, "Reenviando el código...");
      try {
        await axios.get(
          `/api/v2/investor/${ this.InvestorId }/resendVerificationCode?whatsapp=${ isWhatsapp }`
        );
        this.setUpTimerConfig();
        this.initNipCounter();
        this.showSuccess("Código enviado exitosamente");
      } catch (e) {
        this.error_message =
          "Ocurrió  un error al obtener los datos, inténtalo mas tarde.";
      }
      this.showLoading(false);
    },
    async doVerificationCode() {
      this.errorMssg = null;
      if (!(await this.validateForm())) {
        return;
      }
      this.showLoading(true, "Validando tu código...");
      try {
        let payload = {
          investor_id: this.InvestorId,
          verification_code: this.onboardingStepDataForm.verification_code,
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/doVerificationCode",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    async validateForm() {
      this.errorMssg = null;
      return this.$validator.validateAll();
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
      setTimeout(() => this.errorMssg = null, 5000)
    },
    showSuccess(message) {
      this.successMssg = message;
      setTimeout(() => this.successMssg = null, 5000)
    },
    async goToLogin() {
      await this.$router.replace({ name: "login" });
    },
    onSelect({ dialCode }) {
      this.onboardingPersonalData.country_calling_code = `+${dialCode}`;
    },
    hasError(val) {
      return this.errors.has(val);
    },
    errorText(val) {
      return this.errors.first(val);
    },
    isSuccess(val) {
      let ff = this.fields[val];
      return ff && ff.required && ff.valid;
    },
    setUpTimerConfig() {
      window.clearInterval(this.timer);
      this.whatsappTime = 60;
      this.showWhatsapp = false;
    },
    initNipCounter() {
      this.timer = setInterval(() => {
        --this.whatsappTime;
        if(this.whatsappTime == 0){
          this.showWhatsapp = true;
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
};
</script>
