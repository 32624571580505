<template>
    <div>
          <div class="vx-row no-gutter items-center justify-center">
            <investor-onboarding-phone v-if="currentOnboardingStep == '1_signup'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-name v-if="currentOnboardingStep == '2_cellphone_checked'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-birth-data v-if="currentOnboardingStep == '3_name'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-gender v-if="currentOnboardingStep == '4_birth_data'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-rfc-curp v-if="currentOnboardingStep == '5_gender'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-occupation v-if="currentOnboardingStep == '6_rfc_curp'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-kyc-profile v-if="currentOnboardingStep == '7_occupation'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-check-data v-if="currentOnboardingStep == '8_kyc_profile'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-risk-acceptance v-if="currentOnboardingStep == '9_data_check'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-merchant-contract v-if="currentOnboardingStep == '10_risk_acceptance'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-receipt-payments v-if="currentOnboardingStep == '11_merchant_contract'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
            <investor-onboarding-automatic-investment v-if="currentOnboardingStep == '12_receipt_payments'" :onboarding-step-data="onboardingStepData" @updated="getInvestorOnboardingData"/>
          </div>
    </div>
  </template>
  
  <script>
  import inputHelper from "@mixins/inputHelper";
  import InvestorOnboardingPhone from "@views/investor/onboarding/InvestorOnboardingPhone.vue";
  import InvestorOnboardingName from "@views/investor/onboarding/InvestorOnboardingName.vue";
  import InvestorOnboardingBirthData from "@views/investor/onboarding/InvestorOnboardingBirthData.vue";
  import InvestorOnboardingGender from "@views/investor/onboarding/InvestorOnboardingGender.vue";
  import InvestorOnboardingRfcCurp from "@views/investor/onboarding/InvestorOnboardingRfcCurp.vue";
  import InvestorOnboardingOccupation from "@views/investor/onboarding/InvestorOnboardingOccupation.vue";
  import InvestorOnboardingKycProfile from "@views/investor/onboarding/InvestorOnboardingKycProfile.vue";
  import InvestorOnboardingCheckData from "@views/investor/onboarding/InvestorOnboardingCheckData.vue";
  import InvestorOnboardingRiskAcceptance from "@views/investor/onboarding/InvestorOnboardingRiskAcceptance.vue";
  import InvestorOnboardingMerchantContract from "@views/investor/onboarding/InvestorOnboardingMerchantContract.vue";
  import InvestorOnboardingReceiptPayments from "@views/investor/onboarding/InvestorOnboardingReceiptPayments.vue";
  import InvestorOnboardingAutomaticInvestment from "@views/investor/onboarding/InvestorOnboardingAutomaticInvestment.vue";
  import InvestorOnboardingReferalBanner from "@views/investor/onboarding/InvestorOnboardingReferalBanner.vue";
  export default {
    name: "InvestorMainOnboardingStepPagePf",
    mixins: [inputHelper],
    props: ["onboardingStepData"],
    components: {
      InvestorOnboardingPhone,
      InvestorOnboardingName,
      InvestorOnboardingBirthData,
      InvestorOnboardingGender,
      InvestorOnboardingRfcCurp,
      InvestorOnboardingOccupation,
      InvestorOnboardingKycProfile,
      InvestorOnboardingCheckData,
      InvestorOnboardingRiskAcceptance,
      InvestorOnboardingMerchantContract,
      InvestorOnboardingReceiptPayments,
      InvestorOnboardingAutomaticInvestment,
      InvestorOnboardingReferalBanner,
    },
    data(){
      return {
        colClassFull: "vx-col w-full mb-5",
        onboardingStepData: {
          onboarding_step: null,
          phone_number: null,
          rfc: null,
          curp: null,
        }
      }
    },
    computed: {
      currentOnboardingStep(){
        return this.onboardingStepData.onboarding_step;
      },
    },
    methods: {
      getInvestorOnboardingData(){
        this.$emit("updated", 1);
      }
    }
  }
  </script>