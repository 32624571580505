<template>
  <div id="pm_mex_6_fiscal_profile_container" class="vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- MAIN-DIV -->
                  <div class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">Perfil fiscal</p>
                      </div>
                    </div>
                    <vs-divider color="dark"></vs-divider>
                    <!-- CONTENT -->
                    <div class="main-form mt-base">
                      <user-tax-profile :efirma-required="true" :is-edit="isEdit" save-btn-id="pm_mex_6_fiscal_profile_confirm_btn" @on-form-saved="saveDataForm" />
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END MAIN-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import UserTaxProfile from '@components/profile_shared/UserTaxProfile';

export default {
  props: ["onboardingStepData"],
  components: {
    UserTaxProfile
  },
  data() {
    return {
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      colClassFull: "vx-col w-full mb-5",
    };
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    }, 
    isEdit(){
      return this.onboardingStepData.fiscal_validation_timestamp==null?true:false;
    }
  },
  methods: {
    async saveDataForm() {
      this.errorMssg = null;
      this.showLoading(true, "Guardando información...");
      try {
        let payload = {};
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStepPm",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    }
  },
};
</script>
