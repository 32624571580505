<template>
  <div id="10_risk_acceptance_container" :class="cardSizeClass">
    <div class="vx-row no-gutter items-center justify-center p-6">
      <vx-card class="py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="login-tabs-container">
              <div class="vx-row m-0">
                <div class="vx-col w-full p-0" style="min-height: 200px">
                  <!-- WARNINGS-DIV -->
                  <div v-if="currentRiskStep == 0" class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-1xl card-sub-title">
                          Constancia electrónica de riesgos
                        </p>
                        <small>
                          Es importante que conozcas algunos riesgos que existen al invertir con nosotros.<br>
                          Completa la constancia electrónica que se presenta a continuación para conocer sobre estos riesgos, 
                          si no estás de acuerdo no podremos continuar con el proceso de verificación de tu cuenta y no podrás invertir.
                        </small>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row">
                        <div :class="colClassFull">
                          <label class="bold">Advertencias</label>
                        </div>
                      </div>
                      <div class="vx-row mt-4">
                        <div :class="colClass" v-for="warning in warningsData" :key="warning.index">
                          <div class="flex">
                              <vs-avatar color="primary" icon-pack="feather" size="10px" />
                              <div :class="colClassFull">
                                  <label class="bold mt-5">{{warning.title}}</label><br>
                                  <small>{{warning.subtitle}}</small>
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button class="w-full mt-4" @click="showNextStep"
                            >Estoy de acuerdo</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END WARNINGS-DIV -->
                  <!-- RISKS-DIV -->
                  <div v-else-if="currentRiskStep == 1" class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Riesgos
                        </p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row mt-4">
                        <div :class="colClassFull">
                          <vx-card class="mb-4 border-primary" v-for="risk in risksData" :key="risk.index">
                              <vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="7">
                                      <div class="flex flex-wrap justify-start">
                                        <feather-icon v-if="riskRadioAnswers[risk.value] != null && riskRadioAnswers[risk.value] != risk.correctValue" icon="XCircleIcon" svgClasses="w-5 h-5 mr-1 text-danger"/>
                                        <label class="bold">{{risk.title}}</label>
                                      </div>
                                      <small>{{risk.subtitle}}</small>
                                  </vs-col>
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="5">
                                    <div class="flex flex-wrap justify-start ml-2">
                                      <template v-if="risk.correctValue == 0">
                                        <vs-radio :vs-name="risk.title+'_1'" color="dark" class="w-full mb-2" v-model="riskRadioAnswers[risk.value]" :vs-value="1">Sí</vs-radio>
                                        <vs-radio :vs-name="risk.title+'_0'" color="dark" class="w-full" v-model="riskRadioAnswers[risk.value]" :vs-value="0">No, RedGirasol no brinda asesoría financiera</vs-radio>
                                      </template>
                                      <template v-else>
                                        <vs-radio :vs-name="risk.title+'_1'" color="dark" class="w-full mb-2" v-model="riskRadioAnswers[risk.value]" :vs-value="1">Sí, comprendo</vs-radio>
                                        <vs-radio :vs-name="risk.title+'_0'" color="dark" class="w-full" v-model="riskRadioAnswers[risk.value]" :vs-value="0">No</vs-radio>
                                      </template>
                                    </div>
                                  </vs-col>
                              </vs-row>
                          </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button class="w-full mt-4" @click="showNextStep"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END RISKS-DIV -->
                  <!-- INVESTMENTS-DIV -->
                  <div v-else class="px-6 pt-5 pb-5">
                    <!-- HEADER -->
                    <div class="vx-row mb-4">
                      <div class="vx-col w-full">
                        <p class="text-2xl card-sub-title">
                          Acerca de la inversión
                        </p>
                      </div>
                    </div>
                    <!-- CONTENT -->
                    <div class="main-form mt-5">
                      <div class="vx-row mt-4">
                        <div :class="colClassFull">
                          <vx-card class="mb-4 border-primary" v-for="investment in investmentsData" :key="investment.index">
                              <vs-row vs-w="12" vs-justify="center" vs-align="center" vs-type="flex">
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="7">
                                      <div class="flex flex-wrap justify-start">
                                        <feather-icon v-if="investmentsRadioAnswers[investment.value] != null && investmentsRadioAnswers[investment.value] != investment.correctValue" icon="XCircleIcon" svgClasses="w-5 h-5 mr-1 text-danger"/>
                                        <label class="bold">{{investment.title}}</label>
                                      </div>
                                      <small>{{investment.subtitle}}</small>
                                  </vs-col>
                                  <vs-col vs-justify="center" vs-align="center" vs-lg="5">
                                    <div class="flex flex-wrap justify-start ml-2">
                                      <vs-radio :vs-name="investment.title+'_1'" color="dark" class="w-full mb-2" v-model="investmentsRadioAnswers[investment.value]" :vs-value="1">Sí</vs-radio>
                                      <vs-radio :vs-name="investment.title+'_0'" color="dark" class="w-full" v-model="investmentsRadioAnswers[investment.value]" :vs-value="0">No</vs-radio>
                                    </div>
                                  </vs-col>
                              </vs-row>
                          </vx-card>
                        </div>
                      </div>
                      <div class="vx-row">
                        <div class="vx-col md:w-1/2 sm:w-full w-full"></div>
                        <div class="vx-col md:w-1/2 sm:w-full w-full">
                          <vs-button id="10_risk_acceptance_confirm_btn" class="w-full mt-4" @click="showNextStep"
                            >Continuar</vs-button
                          >
                        </div>
                      </div>
                    </div>
                    <!-- END CONTENT DIV -->
                  </div>
                  <!-- END INVESTMENTS-DIV -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
export default {
  mixins: [inputHelper],
  props: ["onboardingStepData"],
  data() {
    return {
      colClassFull: "vx-col w-full",
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      currentRiskStep: 0,
      contractRiskType:15,
      currentLocation:null,
      locationOptions: {
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0
      },
      warningsData:[
        { title: 'Retiro de fondos', subtitle: 'Una vez que inviertas en algún proyecto, no podrás retirar los fondos a menos que se cancele o no logre reunir los fondos requeridos.' },
        { title: 'Retiro anticipado', subtitle: 'No podrás retirar anticipadamente tus inversiones una vez que se complete el fondeo del proyecto.' },
        { title: 'No pago del solicitante', subtitle: 'En caso de que el solicitante no pague su crédito, se presentará una demanda para recuperar el monto del crédito. Existe la posibilidad de perder parcial o totalmente la inversión.' },
        { title: 'Información del solicitante', subtitle: 'Es posible invertir en proyectos que no tengan historial de operación. Existe la posibilidad de perder total o parcialmente la inversión.' },
        { title: 'Información inicial', subtitle: 'Es posible que la información financiera brindada por los solicitantes no sea auditada por un auditor externo. Existe la posibilidad de perder parcial o totalmente la inversión.' },
        { title: 'Analiza la información', subtitle: 'La información a la cual tendrás acceso sobre los créditos podrá ser limitada en comparación a lo observado en el mercado de valores.' },
        { title: 'Rendimientos', subtitle: 'Los rendimientos sobre las inversiones que realices no son garantizados por nosotros ni podemos asegurar que vayan a ser exitosas.' },
        { title: 'Aceptación de riesgos', subtitle: 'Antes de poder invertir, es necesario que comprendas y aceptes los riesgos, formas y términos generales de la plataforma en el cuestionario a continuación.' },
        { title: 'Garantía de recursos', subtitle: 'Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse  o garantizar los recursos de los Clientes que sean utilizados en las Operaciones que celebren con las ITF  o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las  ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.' },
      ],
      risksAnswersCorrect: false,
      riskRadioAnswers: [],
      risksData:[
        { title: 'Asesorías', subtitle: '¿Consideras que RedGirasol deberá brindarte asesoría financiera personalizada para determinar si las inversiones son adecuadas?.', value:'0', correctValue:'0' },
        { title: 'Generación de rendimientos', subtitle: '¿Comprendes que los montos que no estén invertidos no generarán rendimientos?', value:'1', correctValue:'1'},
        { title: 'Disposición de fondos', subtitle: '¿Comprendes que los montos que se encuentren invertidos no podrán ser retirados anticipadamente?', value:'2', correctValue:'1'},
        { title: 'Créditos', subtitle: '¿Comprendes que la información sobre los créditos puede ser limitada?', value:'3', correctValue:'1'},
        { title: 'Pérdida de dinero', subtitle: '¿Comprendes que las inversiones en RedGirasol no están garantizadas, por lo que es posible que pierdas parte o todo tu dinero?', value:'4', correctValue:'1'},
        { title: 'Aprobaciones', subtitle: '¿Comprendes que los créditos publicados no han sido aprobadas por la CNBV u otra autoridad?', value:'5', correctValue:'1'}
      ],
      investmentsRadioAnswers: [],
      investmentsData:[
        { title: 'Comprendo los riesgos', subtitle: 'He leído esta información y comprendo los riesgos de invertir.', value:'0', correctValue:'1' },
        { title: 'Revisión de la información', subtitle: 'Comprendo que deberé leer y entender la información de los solicitantes de crédito.', value:'1', correctValue:'1' },
      ],
    };
  },
  async mounted(){
    if('geolocation' in navigator) {
      await navigator.geolocation.getCurrentPosition(this.onSuccessLocation, this.onErrorLocation, this.locationOptions);
    }
  },
  computed: {
    currentOnboardingStep() {
      return this.onboardingStepData.onboarding_step;
    },
    cardSizeClass(){
      if (this.currentRiskStep == 0) {
        return "vx-col sm:w-5/6 xl:w-4/5 xxl:w-2/3 m-0";
      } else {
        return "vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0";
      }
    }
  },
  methods: {
    async showNextStep(){
      switch (this.currentRiskStep) {
        case 1:
          if (this.correctRisksAnswers() == 6) {
            this.currentRiskStep += 1;
          } else {
            this.errorNotif(
              "Aviso",
              "Deberás responder correctamente todas las anteriores para poder avanzar."
            );
          }
          break;
        case 2:
          if (this.correctInvestmentsAnswers() == 2) {
            await this.saveDataForm();
          } else {
            this.errorNotif(
              "Aviso",
              "Deberás responder correctamente todas las anteriores para poder avanzar."
            );
          }
          break;
        default:
          this.currentRiskStep += 1;
          break;
      }
    },
    async saveDataForm() {
      this.showLoading(true, "Guardando información...");
      try {
        let geolocation = null;
        if(this.currentLocation){
          geolocation = "Lat: "+this.currentLocation.latitude+", Long "+this.currentLocation.longitude;
        }
        let payload = {
          // onboarding_step: this.currentOnboardingStep,
          contractType: this.contractRiskType, 
          geolocation: geolocation
        };
        await axios.post(
          "/api/v2/investor/" + this.InvestorId + "/saveOnboardingStep",
          payload
        );
        this.showLoading(false);
        this.$emit("updated", 1);
      } catch (e) {
        this.showLoading(false);
        this.showError(e);
      }
    },
    showError(e) {
      this.warn(e);
      let err = "Ha ocurrido un error con la operación, intente más tarde";
      if (e.response && e.response.data && e.response.data.error) {
        err = e.response.data.error;
      }
      this.errorMssg = err;
    },
    async onSuccessLocation(pos){
      this.currentLocation = pos.coords;
    },
    onErrorLocation(err){
      // console.log(err);
    },
    correctRisksAnswers(){
      let allAnswers = 0;
      this.riskRadioAnswers.forEach((value, index) => {
        let risk = this.risksData[index];
        if (risk.correctValue == value) {
          allAnswers+=1;
        }
      });      
      return allAnswers;
    },
    correctInvestmentsAnswers(){
      let allAnswers = 0;
      this.investmentsRadioAnswers.forEach((value, index) => {
        let inv = this.investmentsData[index];
        if (inv.correctValue == value) {
          allAnswers+=1;
        }
      });      
      return allAnswers;
    }
  },
};
</script>
<style scoped>
.vx-card.border-primary{
    border-style: solid;
    border-width: 1px;
}
</style>
